import { takeLatest, takeEvery, put } from "redux-saga/effects";
import { eligibilityActions } from "./actions";
import { Action } from "typescript-fsa";
import { EligibilityService } from "../../services/eligibilityService";

import {
  EligibilityDetails,
  DropdownOptionsResponse
} from "../../services/eligibilityService/EligibilityService.type";
import { formatEligibilityDetails } from "./util";

function* EligibilityDetailsWorker(action: Action<string>) {
  try {
    const response: EligibilityDetails = yield EligibilityService.eligibilityDetails(
      action.payload
    );

    const formattedResponse = formatEligibilityDetails(response);
    yield put(
      eligibilityActions.eligibilityDetails.done({
        result: formattedResponse,
        params: action.payload
      })
    );
  } catch (error) {
    yield put(
      eligibilityActions.eligibilityDetails.failed(error.message || error)
    );
  }
}

function* SubIndustriesWorker(action: Action<string>) {
  try {
    const response: DropdownOptionsResponse = yield EligibilityService.getSubIndustries(
      action.payload
    );
    yield put(
      eligibilityActions.getSubIndustries.done({
        result: response.result,
        params: action.payload
      })
    );
  } catch (error) {
    yield put(
      eligibilityActions.getSubIndustries.failed(error.message || error)
    );
  }
}

function* EligibilityWatcher() {
  yield takeEvery(
    eligibilityActions.eligibilityDetails.started,
    EligibilityDetailsWorker
  );
  yield takeLatest(
    eligibilityActions.getSubIndustries.started,
    SubIndustriesWorker
  );
}

export default EligibilityWatcher;
