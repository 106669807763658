import { SerializedStyles } from "@emotion/core";
export const SIZES = {
  xs: 600,
  md: 780,
  ml: 992,
  lg: 1024,
  lgMax: 1366
};
export const regex = {
  phoneNumber: "^[0-9]{10}$",
  FileName: "^[a-zA-Z0-9_]+$",
  gstin:
    "(^[0-9]{2})([A-Za-z]{5}[0-9]{4}[A-Za-z]{1})[1-9A-Za-z]{1}[Zz]{1}[0-9A-Za-z]{1}$"
};

export const PageCount = 10;
export const ProspectPageCount = 30;

export const DsListFetchSize = 10;
export const FileListFetchSize = 5;

export const FileListPollInterval = 50;
export const dsPollInterval = 15;
export const lazyLoadThreshold = 5;

export interface KeyValue {
  [key: string]: string;
}
export interface KeyValueBoolean {
  [key: string]: string | boolean;
}

export interface CustomStyles {
  [key: string]: (data: string) => SerializedStyles;
}

export interface CustomClick {
  [key: string]: (data: KeyValue) => void;
}

export interface CustomFilters {
  filterTitle: string;
  filters: KeyValue;
  selectedFilters: string;
  applyFilter: (filter: string) => void;
  placeholder?: string;
  loading?: boolean;
  allowClear?: boolean;
}
export type Order = "asc" | "desc";

export const errorCodes = {
  "Invalid GSTIN": "201",
  "Invalid Phone Number": "202",
  "Duplicate GSTIN": "203",
  "Technical Failure": "301"
};

export const fileStatusFilters = {
  "upload successful": "1",
  "upload fail": "2",
  "in progress": "3"
};

export const prospectStatus = {
  "On time": "On time",
  Delayed: "Delayed",
  "Not Filed": "Not Filed",
  "Technical Failure": "Technical Failure",
  null: "null"
};

export const returnTypeFilters = {
  GSTR1: "GSTR1",
  GSTR2: "GSTR2",
  GSTR3B: "GSTR3B",
  GSTR4: "GSTR4",
  GSTR9: "GSTR9",
  GSTR9A: "GSTR9A",
  GSTR10: "GSTR10",
  TRAN1: "TRAN1",
  TRAN2: "TRAN2"
};

export const acceptableFileExtensions = ["csv"];

export const FileErrorMessages = {
  invalidFileExtension: "File Extension not supported",
  invalidFileNameFormat:
    "Filename format is invalid. Only alphanumeric and _ allowed",
  invalidFileSize: "File exceeds the maximum size"
};

export interface FileConfig {
  acceptableFileExtensions: string[];
  maximumFileSize: number;
  errorMessages: {
    invalidFileExtension: string;
    invalidFileSize: string;
  };
}

export const DEFAULT_FILE_CONFIG: FileConfig = {
  acceptableFileExtensions: ["pdf"],
  maximumFileSize: 20000000, // specified in bytes- 20MB
  errorMessages: {
    invalidFileExtension: FileErrorMessages.invalidFileExtension,
    invalidFileSize: FileErrorMessages.invalidFileSize
  }
};

export interface TabConfig {
  key: string;
  route: string;
  name: string;
  enabled?: boolean;
}

export const API_ERROR_FEEDBACK = {
  error: "Something went wrong",
  saveError: "Unable to save data",
  downloadError: "Unable to download"
};

export const DATE_FORMAT = "DD-MM-YYYY";

//antd table constants
export const tableScroll = { y: "45vh", x: "max-content" };

export const TABLE_ROW_CLASS_NAME = (_: any, index: number) =>
  index % 2 === 0 ? "even-table-row" : "odd-table-row";

export const TABLE_TOTAL_COUNT = (total: number, range: [number, number]) =>
  `${range[0]}-${range[1]} of ${total} items`;

export const LIST_FETCH_SIZE = 15;

export enum SORT_ORDER {
  Ascend = "ascend",
  Descend = "descend"
}

export const doNothing = () => undefined;

export const POLLING_INTERVAL = 15; //in sec
export const POLLING_DELTA = 5;
