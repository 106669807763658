// Reducers
import { combineReducers } from "redux";
import { FileUploadReducer } from "./reducers/FileUploadReducer";
import { FileListReducer } from "./reducers/FileListReducer";
import { FailedRecordsReducer } from "./reducers/FailedRecordsReducer";
import { ProspectListReducer } from "./reducers/ProspectListReducer";
import { DetailedViewReducer } from "./reducers/DetailedViewReducer";
import { DataSourceReducer } from "./reducers/DataSourceReducer";
import { LoginReducer } from "./reducers/LoginReducer";
import { ResetPasswordReducer } from "./reducers/ResetPasswordReducer";
import { EligibilityReducer } from "./eligibility/reducer";
import { NotificationReducer } from "./notification/reducer";

// State
import { FileListState } from "./state/FileListState";
import { FileUploadState } from "./state/FileUploadState";
import { FailedRecordsState } from "./state/FailedRecordsState";
import { ProspectListState } from "./state/ProspectListState";
import { DetailedViewState } from "./state/DetailedViewState";
import { DataSourceState } from "./state/DataSourceState";
import { LoginState } from "./state/LoginState";
import { ResetPasswordState } from "./state/ResetPasswordState";
import { EligibilityState } from "./eligibility/state";
import { NotificationState } from "./notification/state";
import { KyssReducer, KyssState } from "./reducers/KyssReducer";

export interface ApplicationState {
  login: LoginState;
  resetPassword: ResetPasswordState;
  fileUploads: FileUploadState;
  failedRecords: FailedRecordsState;
  files: FileListState;
  prospects: ProspectListState;
  detailedView: DetailedViewState;
  dataSource: DataSourceState;
  eligibilityState: EligibilityState;
  notificationState: NotificationState;
  kyssState: KyssState;
}

const rootReducer = combineReducers<ApplicationState>({
  login: LoginReducer,
  resetPassword: ResetPasswordReducer,
  fileUploads: FileUploadReducer,
  failedRecords: FailedRecordsReducer,
  files: FileListReducer,
  prospects: ProspectListReducer,
  detailedView: DetailedViewReducer,
  dataSource: DataSourceReducer,
  eligibilityState: EligibilityReducer,
  notificationState: NotificationReducer,
  kyssState: KyssReducer
});

export default rootReducer;
