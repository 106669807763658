/// <summary>
/// Generates url: {AppSettings.service.baseUrl}/{this.serviceApi}
// DO NOT append or prepend '/' here!
export enum ServiceType {
  Auth = "account",
  FileUpload = "files",
  Prospect = "prospect",
  FileList = "document-upload",
  FailedRecord = "failed-record",
  ParsedCSVRecord = "parsed-csv-record",
  TaxFiling = "tax-filing",
  TaxPayer = "audit-log",
  DataSource = "data-source",
  Fields = "fields",
  DataSourceDetails = "map-fields-to-data-source",
  Reports = 'reports',
  Capex = "capex",

  //phase-2
  Anchor = "anchor",
  Eligibility = "loan-request",
  CapexLoanRequest = "capex-loan-request",
  WEP = "wep-gst",
  DMI = "dmi",
  KYC = "kyc-document",
  Document = "document",
  AuditTrail = "audit-trail",
  Notification = "notification"
}

//Channel names of socket connections.
export enum SocketChannels {
  FileList = "process_record",
  FileListDownload = "download_file"
}

//MessageTypes to listen to in a socket connection
export enum SocketMessageType {
  FileList = "REFRESH_FILE_LIST",
  FileListDownload = "FILE_SENT"
}
