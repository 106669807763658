import { QueryPath } from "../QueryPath.data";
import { FailedRecord } from "../../store/state/FailedRecordsState";
import ApiServiceBase from "../ApiServiceBase";
import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";

export class FailedRecordsService {
  public getProspects(
    query: QueryPath
  ): FailedRecord[] | Promise<FailedRecord[]> {
    const service: ApiServiceBase = new ApiService(ServiceType.FailedRecord);
    return service.get(query);
  }
  public getProspectsFilter(fileID: number | string) {
    const service: ApiServiceBase = new ApiService(ServiceType.FailedRecord);
    return service.get({
      route: ["error-code-detail"],
      query: { file_id: fileID }
    });
  }
}

const failedRecordsService = new FailedRecordsService();
export default failedRecordsService;
