import { ResetPasswordPayload } from "../../services/authService/ResetService.data";

export enum ResetPasswordActionTypes {
  forgotPasswordRequest = "FORGOT_PASSWORD_REQUEST",
  forgotPasswordSuccess = "FORGOT_PASSWORD_SUCCESS",
  forgotPasswordError = "FORGOT_PASSWORD_ERROR",
  resetPasswordRequest = "RESET_PASSWORD_REQUEST",
  resetPasswordSuccess = "RESET_PASSWORD_SUCCESS",
  resetPasswordError = "RESET_PASSWORD_ERROR"
}

export interface ResetPasswordAction {
  type: ResetPasswordActionTypes;
  data: ResetPasswordPayload | string | Error | undefined;
}

export class ResetPasswordActions {
  public static forgetPasswordRequest = (
    email: string
  ): ResetPasswordAction => ({
    type: ResetPasswordActionTypes.forgotPasswordRequest,
    data: email
  });

  public static forgetPasswordSuccess = (): ResetPasswordAction => ({
    type: ResetPasswordActionTypes.forgotPasswordSuccess,
    data: undefined
  });

  public static forgetPasswordError = (data: Error): ResetPasswordAction => ({
    type: ResetPasswordActionTypes.forgotPasswordError,
    data
  });

  public static resetPasswordRequest = (
    data: ResetPasswordPayload
  ): ResetPasswordAction => ({
    type: ResetPasswordActionTypes.resetPasswordRequest,
    data
  });

  public static resetPasswordSuccess = (): ResetPasswordAction => ({
    type: ResetPasswordActionTypes.resetPasswordSuccess,
    data: undefined
  });

  public static resetPasswordError = (data: Error): ResetPasswordAction => ({
    type: ResetPasswordActionTypes.resetPasswordError,
    data
  });
}
