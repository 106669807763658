import { reducerWithInitialState } from "typescript-fsa-reducers";
import { NotificationState } from "./state";
import { notificationActions } from "./actions";
import { getUpdatedNotifications, closeNotification } from "./util";

const initialState: NotificationState = {
  notificationMessages: [],
  loading: false,
  closing: false
};

export const NotificationReducer = reducerWithInitialState(initialState)
  .case(notificationActions.getAllNotifications.started, (state) => ({
    ...state,
    loading: true
  }))
  .case(notificationActions.getAllNotifications.failed, (state) => ({
    ...state,
    loading: false
  }))
  .case(notificationActions.getAllNotifications.done, (state, payload) => ({
    ...state,
    notificationMessages: payload.result,
    loading: false
  }))
  .case(notificationActions.getNewNotifications.started, (state) => ({
    ...state,
    loading: true
  }))
  .case(notificationActions.getNewNotifications.failed, (state) => ({
    ...state,
    loading: false
  }))
  .case(notificationActions.getNewNotifications.done, (state, payload) => {
    return {
      ...state,
      notificationMessages: getUpdatedNotifications(state, payload.result),
      loading: false
    };
  })
  .case(notificationActions.closeNotification.started, (state) => ({
    ...state,
    closing: true
  }))
  .case(notificationActions.closeNotification.failed, (state) => ({
    ...state,
    closing: false
  }))
  .case(notificationActions.closeNotification.done, (state, payload) => {
    return {
      ...state,
      notificationMessages: closeNotification(
        state.notificationMessages,
        payload.params
      ),
      closing: false
    };
  });
