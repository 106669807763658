import { FailedRecord, FailedRecordFilter } from "../state/FailedRecordsState";
import { QueryPath } from "../../services/QueryPath.data";

export enum FailedRecordsActionTypes {
  getFailedRecordsRequest = "GET_FAILED_RECORDS_REQUEST",
  getFailedRecordsSuccess = "GET_FAILED_RECORDS_SUCCESS",
  getFailedRecordsError = "GET_FAILED_RECORDS_ERROR",
  setFailedRecordsPages = "SET_FAILED_RECORDS_PAGES",
  downloadFailedRecords = "DOWNLOAD_FAILED_RECORDS_REQUEST",
  getFailedRecordsFilterRequest = "GET_FAILED_RECORDS_FILTER_REQUEST",
  getFailedRecordsFilterSuccess = "GET_FAILED_RECORDS_FILTER_SUCCESS",
  getFailedRecordsFilterError = "GET_FAILED_RECORDS_FILTER_ERROR"
}

export interface FailedRecordsAction {
  type: FailedRecordsActionTypes;
  data?:
    | QueryPath
    | FailedRecord[]
    | number
    | Error
    | string
    | FailedRecordFilter;
}

export class FailedRecordsActions {
  public static getProspectsRequest(data?: QueryPath): FailedRecordsAction {
    return {
      type: FailedRecordsActionTypes.getFailedRecordsRequest,
      data
    };
  }
  public static getProspectsSuccess(data: FailedRecord[]): FailedRecordsAction {
    return {
      type: FailedRecordsActionTypes.getFailedRecordsSuccess,
      data
    };
  }
  public static setNoOfPages(data: number): FailedRecordsAction {
    return {
      type: FailedRecordsActionTypes.setFailedRecordsPages,
      data
    };
  }
  public static getProspectsError(data: Error): FailedRecordsAction {
    return {
      type: FailedRecordsActionTypes.getFailedRecordsError,
      data
    };
  }

  public static downloadFailedRecords(data?: QueryPath): FailedRecordsAction {
    return {
      type: FailedRecordsActionTypes.downloadFailedRecords,
      data
    };
  }
  public static getFilterRequest(data?: number | string): FailedRecordsAction {
    return {
      type: FailedRecordsActionTypes.getFailedRecordsFilterRequest,
      data
    };
  }
  public static getFilterSuccess(
    data: FailedRecordFilter
  ): FailedRecordsAction {
    return {
      type: FailedRecordsActionTypes.getFailedRecordsFilterSuccess,
      data
    };
  }
  public static getFilterError(data: Error): FailedRecordsAction {
    return {
      type: FailedRecordsActionTypes.getFailedRecordsFilterError,
      data
    };
  }
}
