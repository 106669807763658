import get from "lodash/get";

import {
  FileUploadState,
  UniqueFile,
  UploadError
} from "../../store/state/FileUploadState";
import {
  FileUploadAction,
  FileUploadActionTypes
} from "../../store/actions/FileUploadActions";

export const initialState: FileUploadState = {
  loading: false,
  files: {}
};

export const FileUploadReducer = (
  state = initialState,
  action: FileUploadAction
): FileUploadState => {
  switch (action.type) {
    case FileUploadActionTypes.FILE_UPLOAD_REQUEST:
      //Create a key in object with id provided.
      state.files[get(action, "data.id") as string] = {
        progress: { progressPercent: 0, uploaded: 0, total: 0 },
        fileName: (action.data as UniqueFile).file.name,
        error: ""
      };
      return {
        ...state,
        files: { ...state.files },
        loading: (action.data as UniqueFile).upload
      };

    case FileUploadActionTypes.SET_ERROR:
      // Sets error of a file, if id not available it creates it.
      const data = action.data as UploadError;
      if (state.files[data.id]) {
        state.files[data.id].error = data.message;
        state.files[data.id].progress.progressPercent = 0;
      } else {
        //Create a key in object with id provided.
        state.files[get(action, "data.id") as string] = {
          progress: { progressPercent: 0, uploaded: 0, total: 0 },
          fileName: (action.data as UniqueFile).file.name,
          error: ""
        };
      }
      return {
        ...state,
        files: { ...state.files }
      };

    case FileUploadActionTypes.FILE_UPLOAD_SUCCESS:
      //Set Progress to 100% on success(since there is no progress% now)
      state.files[action.data as string].progress.progressPercent = 100;

      return {
        ...state,
        files: { ...state.files },
        loading: false
      };
    case FileUploadActionTypes.FILE_UPLOAD_ERROR:
      const error = action.data as UploadError;
      //Marking that file Upload is not SSuccessful
      state.files[error.id].error = error.message;
      state.files[error.id].progress.progressPercent = 0;

      return {
        ...state,
        files: { ...state.files },
        loading: false
      };
    case FileUploadActionTypes.FILE_UPLOAD_PROGRESS:
      const progress = get(action, "data.progress");
      state.files[get(action, "data.id") as string].progress = progress;
      return {
        ...state,
        files: { ...state.files }
      };
    default:
      return state;
  }
};
