import { takeLatest, takeEvery, put } from "redux-saga/effects";

import {
  FailedRecordsAction,
  FailedRecordsActions,
  FailedRecordsActionTypes
} from "../actions/FailedRecordsActions";

import { NotificationMessage } from "../../constants/NotificationMessages";
import { PageCount } from "../../Constants";

import FailedRecordsService from "../../services/failedRecordsService/FailedRecordsService";
import { FailedRecord, FailedRecordFilter } from "../state/FailedRecordsState";
import { QueryPath } from "../../services/QueryPath.data";
import DownloadService from "../../services/downloadService/DownloadService";
import { notification } from "antd";

export function* FailedRecordsWorker(
  action: FailedRecordsAction
): IterableIterator<any> {
  try {
    const response: any = yield FailedRecordsService.getProspects(
      action.data as QueryPath
    );
    const data: any[] = [];
    response.results.forEach((item: any) => {
      //adding 'reason' to the object
      data.push({
        reason: item.error_code,
        ...item.failed_record
      });
    });
    yield put(
      FailedRecordsActions.setNoOfPages(Math.ceil(response.count / PageCount))
    );
    yield put(FailedRecordsActions.getProspectsSuccess(data as FailedRecord[]));
  } catch (error) {
    yield put(FailedRecordsActions.getProspectsError(error));

    notification.error({
      message: "Unable to Fetch DATA",
      description: error.message || error
    });
  }
}

export function* DownloadFailedRecordsWorker(
  action: FailedRecordsAction
): IterableIterator<any> {
  try {
    const query = (action.data as QueryPath).query;

    yield DownloadService.downloadFailedRecords({
      route: ["download"],
      query
    });
    notification.info({ message: NotificationMessage.downloadFileInfo });
  } catch (error) {
    notification.error({
      message: "Unable to Download",
      description: error.message || error
    });
  }
}

export function* FailedRecordFilterWorker(
  action: FailedRecordsAction
): IterableIterator<any> {
  try {
    const response: any = yield FailedRecordsService.getProspectsFilter(
      action.data as number | string
    );

    const failedRecordFilter: FailedRecordFilter = {
      options: {},
      count: {}
    };

    Object.keys(response).forEach((key) => {
      failedRecordFilter.options[key] = response[key].error_code;
      failedRecordFilter.count[key] = response[key].count;
    });

    yield put(FailedRecordsActions.getFilterSuccess(failedRecordFilter));
  } catch (error) {
    yield put(FailedRecordsActions.getFilterError(error));
  }
}

function* FailedRecordsWatcher(): IterableIterator<any> {
  yield takeLatest(FailedRecordsActionTypes.getFailedRecordsRequest, (action) =>
    FailedRecordsWorker({
      type: action.type,
      data: (action as FailedRecordsAction).data
    })
  );

  yield takeLatest(
    FailedRecordsActionTypes.getFailedRecordsFilterRequest,
    (action) =>
      FailedRecordFilterWorker({
        type: action.type,
        data: (action as FailedRecordsAction).data
      })
  );
  yield takeEvery(FailedRecordsActionTypes.downloadFailedRecords, (action) =>
    DownloadFailedRecordsWorker({
      type: action.type,
      data: (action as FailedRecordsAction).data
    })
  );
}

export default FailedRecordsWatcher;
