import { routes, AnchorRoutes } from "../../Routes";

//Icons
import ClientsIcon from "../../assets/clients.svg";
import DashboardIcon from "../../assets/dashboard.svg";
import ProspectingIcon from "../../assets/prospecting.svg";
import { UserPermissions } from "constants/UserPermissions";
import KYSSIcon from "../../assets/kyss.svg";

export interface SidebarItem {
  name: string;
  route: string;
  icon?: string;
  permission?: UserPermissions;
}

export interface SideBarConstant {
  [key: string]: SidebarItem;
}

export const SidebarComponentLabels = {
  Jitfin: "Jitfin",
  logout: "Logout",
  logoutMessage: "You have been Successfully Logged Out!"
};

export const sidebarItems: SideBarConstant = {
  ClientManagement: {
    name: "CLIENT MANAGEMENT",
    icon: ClientsIcon,
    route: routes.files.new
  },
  ProspectList: {
    name: "Prospect list",
    route: routes.prospects.new,
    permission: UserPermissions.viewProspectList
  },
  FileUploads: {
    name: "File uploads",
    route: routes.files.new,
    permission: UserPermissions.viewUploadedFiles
  },
  DataSourceManagement: {
    name: "Data Source Management",
    route: routes.dataSourceView.new,
    permission: UserPermissions.viewDataSourceList
  },

  prospecting: { name: "PROSPECTING", icon: ProspectingIcon, route: "" },
  Dashboard: {
    name: "DASHBOARD",
    icon: DashboardIcon,
    route: routes.home.new,
    permission: UserPermissions.ViewEligibilityList
  },
  UserActivity: {
    name: "User Activity",
    route: routes.userActivity.new,
    permission: UserPermissions.ViewEligibilityList
  },
  CapexUserActivity: {
    name: "Capex User Activity",
    route: routes.capexUserActivity.new,
    permission: UserPermissions.ViewEligibilityList
  },
  Anchor: {
    name: "Anchor",
    route: routes.anchor.view(AnchorRoutes.list),
    permission: UserPermissions.ViewAnchorList
  },
  PayBills: {
    name: "Pay Bills",
    route: routes.challanListView.new,
    permission: UserPermissions.ViewBills
  },
  KYSS: {
    name: "KYSS",
    route: routes.kyss.new,
    icon: KYSSIcon,
    permission: UserPermissions.ViewEligibilityList
  }
};

const availableProspectingListRoutes: SidebarItem[] = [
  sidebarItems.ProspectList,
  sidebarItems.FileUploads,
  sidebarItems.DataSourceManagement
];

const availableClientManagementList: SidebarItem[] = [
  sidebarItems.UserActivity,
  sidebarItems.CapexUserActivity,
  sidebarItems.Anchor,
  sidebarItems.PayBills
];

export const sidebarRoutes = (permissions: UserPermissions[]) => {
  const prospectingRoutes: SidebarItem[] = availableProspectingListRoutes.filter(
    (route: SidebarItem) =>
      !!route.permission && permissions.includes(route.permission)
  );
  const clientManagementRoutes: SidebarItem[] = availableClientManagementList.filter(
    (route: SidebarItem) =>
      !!route.permission && permissions.includes(route.permission)
  );

  return { prospectingRoutes, clientManagementRoutes };
};
