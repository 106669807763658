import { ProspectListState } from "../state/ProspectListState";
import { QueryPath } from "../../services/QueryPath.data";
import { AddProspectRequest } from "../../services/prospectListService/ProspectListService.type";
import { KeyValue } from "../../Constants";

export enum ProspectListActionTypes {
  getProspectsRequest = "GET_PROSPECTS_REQUEST",
  getProspectsSuccess = "GET_PROSPECTS_SUCCESS",
  getProspectsError = "GET_PROSPECTS_ERROR",

  addProspectRequest = "ADD_PROSPECT_REQUEST",
  addProspectSuccess = "ADD_PROSPECT_SUCCESS",
  addProspectError = "ADD_PROSPECT_ERROR",

  clearError = "CLEAR_ERROR",

  //it's not specific because, it downloads entire file without any filtering
  downloadFileRequest = "DOWNLOAD_FILE_REQUEST",
  downloadFileSuccess = "DOWNLOAD_FILE_SUCCESS",
  downloadFileError = "DOWNLOAD_FILE_ERROR"
}

export interface DownloadFile {
  fileName: string;
  fileID: number;
}

export interface AddProspectRedirect {
  request: AddProspectRequest;
  setErrors: (errors: KeyValue) => void;
  redirect: () => void;
}

export interface ProspectListAction {
  type: ProspectListActionTypes;
  data?:
    | QueryPath
    | ProspectListState
    | AddProspectRedirect
    | DownloadFile
    | Error;
}

export class ProspectListActions {
  public static getProspectsRequest(data?: QueryPath): ProspectListAction {
    return {
      type: ProspectListActionTypes.getProspectsRequest,
      data
    };
  }
  public static getProspectsSuccess(
    data: ProspectListState
  ): ProspectListAction {
    return {
      type: ProspectListActionTypes.getProspectsSuccess,
      data
    };
  }
  public static getProspectsError(data: Error): ProspectListAction {
    return {
      type: ProspectListActionTypes.getProspectsError,
      data
    };
  }

  public static addProspectRequest(
    data: AddProspectRedirect
  ): ProspectListAction {
    return {
      type: ProspectListActionTypes.addProspectRequest,
      data
    };
  }

  public static addProspectSuccess(): ProspectListAction {
    return {
      type: ProspectListActionTypes.addProspectSuccess,
      data: undefined
    };
  }
  public static addProspectError(data: Error): ProspectListAction {
    return {
      type: ProspectListActionTypes.addProspectError,
      data
    };
  }

  public static clearError = (): ProspectListAction => ({
    type: ProspectListActionTypes.clearError
  });

  public static downloadFileRequest = (
    fileName: string,
    fileID: number
  ): ProspectListAction => ({
    type: ProspectListActionTypes.downloadFileRequest,
    data: { fileName, fileID }
  });
  public static downloadFileSuccess = (): ProspectListAction => ({
    type: ProspectListActionTypes.downloadFileSuccess
  });
  public static downloadFileError = (): ProspectListAction => ({
    type: ProspectListActionTypes.downloadFileError
  });
}
