import ApiServiceBase from "../ApiServiceBase";
import ApiService from "../ApiService";
import { ServiceType } from "../ServiceType.data";

import {
  EligibilityListResponse,
  EligibilityListPayload,
  ManualLoanAmountData,
  ManualLoanAmountDataResponse,
  DropdownOptionsResponse,
  UpdateAccountCreationStatus,
  AcceptRejectReceivables,
  UpdateReceivableAmount,
  OpportunityLostPayload,
  KYCOnHoldPayload,
  KYCFailurePayload,
  CapexEligibilityResponse,
  ResponseWithPresignedUrlData,
  DocType
} from "./EligibilityService.type";
import { QueryPath } from "../../services/QueryPath.data";
import { ResponseWithStatus } from "./LoanStatus.type";

export class EligibilityListService {
  public uploadGstrConfig: QueryPath = { route: ["upload-document"] };
  public deleteGstrConfig: QueryPath = { route: ["delete-gst-doc"] };

  public getEligibilityList(
    data?: EligibilityListPayload
  ): EligibilityListResponse | Promise<EligibilityListResponse> {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.get({
      route: ["eligibility-list"],
      query: { ...data }
    });
  }
  public getCapexEligibilityList(
    data?: EligibilityListPayload
  ): CapexEligibilityResponse | Promise<CapexEligibilityResponse> {
    const service: ApiServiceBase = new ApiService(ServiceType.Capex);
    return service.get({
      route: ["filter-loan-request"],
      query: { ...data }
    });
  }
  public getIndustries():
    | DropdownOptionsResponse
    | Promise<DropdownOptionsResponse> {
    const service: ApiServiceBase = new ApiService(ServiceType.Anchor);

    return service.get({ route: ["industry-type"] });
  }
  public getSubIndustries(
    industry: string
  ): DropdownOptionsResponse | Promise<DropdownOptionsResponse> {
    const service: ApiServiceBase = new ApiService(ServiceType.Anchor);

    return service.get({ route: ["sub-industries"], query: { industry } });
  }

  public eligibilityDetails(eligibilityId: string) {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.get({ query: { eligibilityId } });
  }

  public getCapexEligibilityDetails(eligibilityId: string) {
    const service: ApiServiceBase = new ApiService(ServiceType.CapexLoanRequest);
    return service.get({ query: { eligibilityId } });
  }

  public presignedDocsRequest(data: DocType): ResponseWithPresignedUrlData<any> | Promise<ResponseWithPresignedUrlData<any>> {
    const service: ApiServiceBase = new ApiService(ServiceType.Capex);
    return service.get(
      {
        route: ['cp-admin-flow', `${data.eligibilityId}`, 'presigned-doc-urls'],
        query: { docs_type : `${data.docTypeValue}` }
      }
    );
  }

  public getSitePhotos(data: DocType) {
    const service: ApiServiceBase = new ApiService(ServiceType.Capex);
    return service.get(
      {
        route: ['cp-admin-flow', `${data.eligibilityId}`, 'presigned-doc-urls'],
        query: { docs_type : `${data.docTypeValue}` }
      }
    );
  }

  public uploadByAdmin(data: any) {
    const service: ApiServiceBase = new ApiService(ServiceType.Capex);
    return service.post(
        {
          route: ['upload-by-admin', `${data.eligibilityId}`],
        },
        {
          doc_type: data.doc_type,
          amount: data.amount,
          invoice_number: data?.invoice_number || ''
        }
    );
  }

  public addBankDetails(data: any) {
    const service: ApiServiceBase = new ApiService(ServiceType.Capex);
    return service.post(
        {
          route: ['add-disbursal-bank-account', `${data.eligibilityId}`],
        },
        {
          beneficiaryAccountName: data?.beneficiaryAccountName,
          accountNumber: data?.accountNumber,
          bankName: data?.bankName,
          ifscCode: data?.ifscCode,
          branchName: data?.branchName,
        }
    );
  }

  public approveRejectByAdmin(data: any) {
    const service: ApiServiceBase = new ApiService(ServiceType.Capex);
    return service.post(
        {
          route: ['verify-by-admin', `${data.eligibilityId}`],
        },
        {
          doc_type: data.doc_type,
          verified: data.verified
        }
    );
  }

  public approvePhotosByAdmin(data: any) {
    const service: ApiServiceBase = new ApiService(ServiceType.Capex);
    return service.post(
        {
          route: ['verify-by-admin', `${data.eligibilityId}`],
        },
        {
          doc_type: data.doc_type,
          verified: data.verified
        }
    );
  }

  public rejectPhotosByAdmin(data: any) {
    const service: ApiServiceBase = new ApiService(ServiceType.Capex);
    return service.post(
        {
          route: ['delete-site-photos'],
        },
        {
          eligibility_id: data.eligibilityId,
          reason: data.reason,
        }
    );
  }

  public cancelRecord(data: any) {
    const service: ApiServiceBase = new ApiService(ServiceType.Capex);
    return service.post(
        {
          route: ['cancel-deal', `${data.eligibilityId}`],
        },
        {
          data
        }
    );
  }

  public estimatePreviousStage(data: any) {
    const service: ApiServiceBase = new ApiService(ServiceType.Capex);
    return service.post(
        {
          route: ['move-previous-stage', `${data.eligibilityId}`],
        },
        {
          doc_type: data.doc_type,
        }
    );
  }

  public invoicePreviousStage(data: any) {
    const service: ApiServiceBase = new ApiService(ServiceType.Capex);
    return service.post(
        {
          route: ['move-previous-stage', `${data.eligibilityId}`],
        },
        {
          doc_type: data.doc_type,
        }
    );
  }

  public triggerManualFileVerification(eligibilityId: string) {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);

    return service.post(
      { route: ["manual-file-verification"] },
      { eligibilityId }
    );
  }

  public manualLoanCalculation(
    data: ManualLoanAmountData
  ): Promise<ManualLoanAmountDataResponse> | ManualLoanAmountDataResponse {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.post({ route: ["calculate-loan"] }, data);
  }

  public updateEligibilityStatus(
    payload: OpportunityLostPayload | KYCOnHoldPayload | KYCFailurePayload
  ): Promise<ResponseWithStatus> | ResponseWithStatus {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.post({ route: ["status"] }, payload);
  }

  public updateAccountCreationStatus(
    data: UpdateAccountCreationStatus
  ): Promise<ResponseWithStatus> | ResponseWithStatus {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);
    return service.post({ route: ["disbursal-bank-account", "status"] }, data);
  }

  public acceptRejectReceivables(
    data: AcceptRejectReceivables
  ): ResponseWithStatus | Promise<ResponseWithStatus> {
    const service: ApiServiceBase = new ApiService(ServiceType.Anchor);

    return service.post({ route: ["update-receivables-status"] }, data);
  }

  public updateReceivableAmount(
    data: UpdateReceivableAmount
  ): ResponseWithStatus | Promise<ResponseWithStatus> {
    const service: ApiServiceBase = new ApiService(ServiceType.Anchor);
    return service.post(
      { route: ["update-receivables-status"] },
      { ...data, isUpdate: true }
    );
  }
}

const eligibilityListService = new EligibilityListService();
export default eligibilityListService;
