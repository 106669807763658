import { FileDetails } from "../state/FileListState";
import { QueryPath } from "../../services/QueryPath.data";

export enum FetchFileActionTypes {
  FETCH_FILE_REQUEST = "FETCH_FILE_REQUEST",
  FETCH_FILE_PROGRESS = "FETCH_FILE_PROGRESS",
  FETCH_FILE_SUCCESS = "FETCH_FILE_SUCCESS",
  FETCH_FILE_BATCH_SUCCESS = "FETCH_FILE_BATCH_SUCCESS",
  FETCH_FILE_ERROR = "FETCH_FILE_ERROR",
  REFRESH_FILE_REQUEST = "REFRESH_FILE_REQUEST",
  REFRESH_FILE_SUCCESS = "REFRESH_FILE_SUCCESS",
  REFRESH_FILE_ERROR = "REFRESH_FILE_ERROR",
  SET_FILE_LIST_COUNT = "SET_FILE_LIST_COUNT",
  POLL_FILE_LIST_REQUEST = "POLL_FILE_LIST_REQUEST",
  POLL_FILE_LIST_SUCCESS = "POLL_FILE_LIST_SUCCESS",

  SAVE_FILE_DETAILS_REQUEST = "SAVE_FILE_DETAILS_REQUEST",
  SAVE_FILE_DETAILS_SUCCESS = "SAVE_FILE_DETAILS_SUCCESS",
  SAVE_FILE_DETAILS_ERROR = "SAVE_FILE_DETAILS_ERROR"
}

export interface FetchFileAction {
  type: FetchFileActionTypes;
  data?: QueryPath | FileDetails[] | number | Error | FileDetails;
}
export class FetchFileActions {
  public static fetchFileRequest(data?: QueryPath): FetchFileAction {
    return {
      type: FetchFileActionTypes.FETCH_FILE_REQUEST,
      data
    };
  }
  public static fetchFileSuccess(data: FileDetails[]): FetchFileAction {
    return {
      type: FetchFileActionTypes.FETCH_FILE_SUCCESS,
      data
    };
  }
  public static fetchFileBatchSuccess(data: FileDetails[]): FetchFileAction {
    return {
      type: FetchFileActionTypes.FETCH_FILE_BATCH_SUCCESS,
      data
    };
  }
  public static fetchFileError(data: Error): FetchFileAction {
    return {
      type: FetchFileActionTypes.FETCH_FILE_ERROR,
      data
    };
  }

  public static refreshFileRequest(data?: QueryPath): FetchFileAction {
    return {
      type: FetchFileActionTypes.REFRESH_FILE_REQUEST,
      data
    };
  }
  public static refreshFileSuccess(data: FileDetails[]): FetchFileAction {
    return {
      type: FetchFileActionTypes.REFRESH_FILE_SUCCESS,
      data
    };
  }
  public static refreshFileError(data: Error): FetchFileAction {
    return {
      type: FetchFileActionTypes.REFRESH_FILE_ERROR,
      data
    };
  }

  public static setFileListCount(data: number): FetchFileAction {
    return {
      type: FetchFileActionTypes.SET_FILE_LIST_COUNT,
      data
    };
  }

  public static pollFileListRequest(): FetchFileAction {
    return {
      type: FetchFileActionTypes.POLL_FILE_LIST_REQUEST
    };
  }

  public static pollFileListSuccess(data: FileDetails[]): FetchFileAction {
    return {
      type: FetchFileActionTypes.POLL_FILE_LIST_SUCCESS,
      data
    };
  }

  public static saveFileDetailsRequest(data: number): FetchFileAction {
    return {
      type: FetchFileActionTypes.SAVE_FILE_DETAILS_REQUEST,
      data
    };
  }
  public static saveFileDetailsSuccess(data: FileDetails): FetchFileAction {
    return {
      type: FetchFileActionTypes.SAVE_FILE_DETAILS_SUCCESS,
      data
    };
  }
  public static saveFileDetailsError(data: Error): FetchFileAction {
    return {
      type: FetchFileActionTypes.SAVE_FILE_DETAILS_ERROR,
      data
    };
  }
}
