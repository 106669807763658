import { UniqueFile } from "../store/state/FileUploadState";
import {
  acceptableFileExtensions,
  FileErrorMessages,
  regex,
  FileConfig
} from "../Constants";
import { RcFile } from "antd/lib/upload";

export const validateProspectsFile = (data: UniqueFile): string => {
  const { file } = data;

  if (
    file &&
    !acceptableFileExtensions.some((extension: string) =>
      file.name.endsWith(extension)
    )
  ) {
    return FileErrorMessages.invalidFileExtension;
  } else if (file && file.name.split(".")[0].search(regex.FileName) === -1) {
    return FileErrorMessages.invalidFileNameFormat;
  }
  return "";
};

export const validateFile = (file: RcFile, fileConfig: FileConfig): string => {
  if (
    !fileConfig.acceptableFileExtensions.some((extension: string) =>
      file?.name.toLowerCase().endsWith(extension)
    )
  ) {
    return fileConfig.errorMessages.invalidFileExtension;
  } else if (file?.size > fileConfig.maximumFileSize) {
    return fileConfig.errorMessages.invalidFileSize;
  }
  return "";
};
