import { AccessDetails } from "../../store/state/LoginState";

export enum LoginActionTypes {
  loginRequest = "LOGIN_REQUEST",
  loginSuccess = "LOGIN_SUCCESS",
  loginError = "LOGIN_ERROR",

  logoutRequest = "LOGOUT_REQUEST",
  logoutSuccess = "LOGOUT_SUCCESS",
  logoutError = "LOGOUT_ERROR"
}

export interface LoginRequest {
  username?: string;
  password?: string;
  token?: string;
  redirect?: () => void;
}

export interface LoginAction {
  type: LoginActionTypes;
  data?: LoginRequest | Error | AccessDetails;
}

export class LoginActions {
  public static LoginRequest(data: LoginRequest): LoginAction {
    return { type: LoginActionTypes.loginRequest, data };
  }
  public static LoginSuccess(data: AccessDetails): LoginAction {
    return { type: LoginActionTypes.loginSuccess, data };
  }
  public static LoginError(data: Error): LoginAction {
    return { type: LoginActionTypes.loginError, data };
  }

  public static LogoutRequest(): LoginAction {
    return { type: LoginActionTypes.logoutRequest };
  }
  public static LogoutSuccess(): LoginAction {
    return { type: LoginActionTypes.logoutSuccess };
  }
  public static LogoutError(data: Error): LoginAction {
    return { type: LoginActionTypes.logoutError, data };
  }
}
