export enum LogLevel {
  None,
  Errors,
  Debug,
  Trace
}

//set REACT_APP_BASE_URL in .env file
export const BaseUrl = process.env.REACT_APP_BASE_URL as string;
export interface TestSettings {
  readonly testing: boolean;
  readonly logLevel: LogLevel;
}

export class AppSettings {
  public static readonly server = {
    baseUrl: BaseUrl // DO NOT end with '/'!
  };
  public static readonly logging = {
    logLevel: LogLevel.Trace
  };
}
