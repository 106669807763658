import useLogin from "../../store/hooks/auth/useLogin";
import { useCallback, useMemo } from "react";
import { UserPermissions } from "constants/UserPermissions";

const useFeatureFlags = () => {
  const {
    loginState: { accessDetails }
  } = useLogin();

  const hasPermission = useCallback(
    (type: UserPermissions): boolean =>
      !!accessDetails?.permissions.includes(type),
    [accessDetails]
  );

  const featureFlags = useMemo(
    () => ({
      enableNotifications: hasPermission(UserPermissions.ViewNotifications),

      enableEligibilityList: hasPermission(UserPermissions.ViewEligibilityList),
      enableUserActivity: hasPermission(UserPermissions.ViewUserActivity),
      enableCapexUserActivity: hasPermission(UserPermissions.ViewEligibilityList),

      enableViewBills: hasPermission(UserPermissions.ViewBills),

      enableBillsActions: hasPermission(UserPermissions.PayBills),
      enableAnchorCreation: hasPermission(UserPermissions.CreateAnchor),
      enableViewAnchor: hasPermission(UserPermissions.ViewAnchorList)
    }),
    [hasPermission]
  );

  return featureFlags;
};

export default useFeatureFlags;
