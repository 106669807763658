import { ResetPasswordState } from "../../store/state/ResetPasswordState";
import {
  ResetPasswordAction,
  ResetPasswordActionTypes
} from "../../store/actions/ResetActions";

export const initialState: ResetPasswordState = {
  loading: false,
  success: false
};

export const ResetPasswordReducer = (
  state = initialState,
  action: ResetPasswordAction
): ResetPasswordState => {
  switch (action.type) {
    case ResetPasswordActionTypes.forgotPasswordRequest:
      return {
        loading: true,
        success: false
      };

    case ResetPasswordActionTypes.forgotPasswordSuccess:
      return {
        loading: false,
        success: true
      };

    case ResetPasswordActionTypes.forgotPasswordError:
      return {
        loading: false,
        error: action.data as string
      };

    case ResetPasswordActionTypes.resetPasswordRequest:
      return {
        loading: true,
        success: false
      };

    case ResetPasswordActionTypes.resetPasswordSuccess:
      return {
        loading: false,
        success: true
      };

    case ResetPasswordActionTypes.resetPasswordError:
      return {
        loading: false,
        error: action.data as string
      };

    default:
      return state;
  }
};
