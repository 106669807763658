import {
  FailedRecordsState,
  FailedRecord,
  FailedRecordFilter
} from "../state/FailedRecordsState";
import {
  FailedRecordsAction,
  FailedRecordsActionTypes
} from "../actions/FailedRecordsActions";

export const initialState: FailedRecordsState = {
  noOfPages: 1,
  failedRecords: undefined,
  loading: false,
  filters: undefined
};

export const FailedRecordsReducer = (
  state = initialState,
  action: FailedRecordsAction
): FailedRecordsState => {
  switch (action.type) {
    case FailedRecordsActionTypes.getFailedRecordsRequest:
      return {
        ...state,
        loading: true,
        error: undefined
      };

    case FailedRecordsActionTypes.getFailedRecordsSuccess:
      return {
        ...state,
        loading: false,
        error: undefined,
        failedRecords: action.data as FailedRecord[]
      };
    case FailedRecordsActionTypes.getFailedRecordsError:
      return {
        ...state,
        loading: false,
        failedRecords: undefined,
        error: action.data
      };
    case FailedRecordsActionTypes.setFailedRecordsPages:
      return {
        ...state,
        noOfPages: action.data as number
      };
    case FailedRecordsActionTypes.getFailedRecordsFilterRequest:
      return {
        ...state,
        filters: {
          ...state.filters,
          filterLoading: true,
          filterError: undefined,
          count: {},
          options: {}
        }
      };
    case FailedRecordsActionTypes.getFailedRecordsFilterSuccess:
      return {
        ...state,
        filters: {
          ...(action.data as FailedRecordFilter),
          filterLoading: false,
          filterError: undefined
        }
      };
    case FailedRecordsActionTypes.getFailedRecordsFilterError:
      return {
        ...state,
        filters: {
          ...state.filters,
          filterLoading: false,
          filterError: true,
          count: {},
          options: {}
        }
      };

    default:
      return state;
  }
};
