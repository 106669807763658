import {
  DetailedViewState,
  UploadedData,
  TaxFilingData,
  TaxPayerData
} from "../state/DetailedViewState";
import {
  DetailedViewAction,
  DetailedViewActionTypes
} from "../actions/DetailedViewActions";

export const initialState: DetailedViewState = {
  uploadedData: undefined,
  taxFilingData: { taxFiling: [], refreshing: false },
  taxPayerData: undefined,
  loading: false
};

// tslint:disable-next-line: cyclomatic-complexity
export const DetailedViewReducer = (
  state = initialState,
  action: DetailedViewAction
): DetailedViewState => {
  switch (action.type) {
    case DetailedViewActionTypes.UploadProspectDataRequest:
      return {
        ...state,
        loading: true,
        uploadedData: undefined,
        error: undefined
      };

    case DetailedViewActionTypes.UploadProspectDataSuccess:
      return {
        ...state,
        loading: false,
        error: undefined,
        uploadedData: action.data as UploadedData[]
      };
    case DetailedViewActionTypes.UploadProspectDataError:
      return {
        ...state,
        loading: false,
        error: action.data
      };

    case DetailedViewActionTypes.EditUploadProspectDataRequest:
      return {
        ...state,
        loading: true,
        error: undefined
      };

    case DetailedViewActionTypes.EditUploadProspectDataSuccess:
      return {
        ...state,
        loading: false,
        error: undefined
      };
    case DetailedViewActionTypes.EditUploadProspectDataError:
      return {
        ...state,
        loading: false,
        error: action.data
      };

    case DetailedViewActionTypes.TaxFilingRequest:
      return {
        ...state,
        loading: true,
        error: undefined
      };

    case DetailedViewActionTypes.TaxFilingSuccess:
      return {
        ...state,
        loading: false,
        error: undefined,
        taxFilingData: action.data as TaxFilingData
      };
    case DetailedViewActionTypes.TaxFilingError:
      return {
        ...state,
        loading: false,
        error: action.data
      };

    case DetailedViewActionTypes.RefreshTaxFilingRequest:
      return {
        ...state,
        loading: true,
        taxFilingData: { ...state.taxFilingData, refreshing: true },
        error: undefined
      };

    case DetailedViewActionTypes.RefreshTaxFilingSuccess:
      return {
        ...state,
        loading: false,
        error: undefined,
        taxFilingData: { ...state.taxFilingData, refreshing: false }
      };
    case DetailedViewActionTypes.RefreshTaxFilingError:
      return {
        ...state,
        loading: false,
        error: action.data,
        taxFilingData: { ...state.taxFilingData, refreshing: false }
      };

    case DetailedViewActionTypes.TaxPayerRequest:
      return {
        ...state,
        loading: true,
        taxPayerData: undefined,
        error: undefined
      };

    case DetailedViewActionTypes.TaxPayerSuccess:
      return {
        ...state,
        loading: false,
        error: undefined,
        taxPayerData: action.data as TaxPayerData
      };
    case DetailedViewActionTypes.TaxPayerError:
      return {
        ...state,
        loading: false,
        error: action.data
      };
    default:
      return state;
  }
};
