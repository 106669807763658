import { FileListState, FileDetails } from "../../store/state/FileListState";

import {
  FetchFileAction,
  FetchFileActionTypes
} from "../../store/actions/FileListActions";

export const initialState: FileListState = {
  loading: false,
  fileList: undefined,
  refreshing: false
};

// tslint:disable-next-line: cyclomatic-complexity
export const FileListReducer = (
  state = initialState,
  action: FetchFileAction
): FileListState => {
  switch (action.type) {
    case FetchFileActionTypes.FETCH_FILE_REQUEST:
      //NOTE: don't make fileList undefined!!
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case FetchFileActionTypes.FETCH_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        fileList: action.data as FileDetails[]
      };
    case FetchFileActionTypes.FETCH_FILE_BATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        fileList: [
          //if there is previous data, adding it
          ...(state.fileList || []),
          //Appending new page of data with previous data
          ...(action.data as FileDetails[])
        ]
      };
    case FetchFileActionTypes.FETCH_FILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data
      };

    case FetchFileActionTypes.REFRESH_FILE_REQUEST:
      return {
        ...state,
        loading: true,
        refreshing: true,
        error: undefined
      };
    case FetchFileActionTypes.REFRESH_FILE_SUCCESS:
      return {
        ...state,
        refreshing: false,
        loading: false,
        error: undefined,
        fileList: action.data as FileDetails[]
      };
    case FetchFileActionTypes.REFRESH_FILE_ERROR:
      return {
        ...state,
        refreshing: false,
        loading: false,
        error: action.data
      };
    //Currently no change in state when fetching file details
    case FetchFileActionTypes.SAVE_FILE_DETAILS_REQUEST:
      return {
        ...state
      };
    case FetchFileActionTypes.SAVE_FILE_DETAILS_SUCCESS:
      return {
        ...state,
        fileDetails: action.data as FileDetails
      };
    case FetchFileActionTypes.SAVE_FILE_DETAILS_ERROR:
      return {
        ...state,
        fileDetails: undefined
      };
    case FetchFileActionTypes.SET_FILE_LIST_COUNT:
      return { ...state, fileListCount: action.data as number };

    case FetchFileActionTypes.POLL_FILE_LIST_SUCCESS:
      const updates = action.data as FileDetails[];
      const fileList = state.fileList;
      if (updates.length && fileList && fileList.length) {
        updates.forEach((item) => {
          fileList.forEach((file, index) => {
            if (file.id === item.id && state.fileList) {
              state.fileList[index] = { ...item };
            }
          });
        });
      }
      return {
        ...state
      };
    default:
      return state;
  }
};
