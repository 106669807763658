import ApiServiceBase from "../ApiServiceBase";
import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";
import { KeyValue } from "../../Constants";

export class TaxFilingService {
  public getTaxFilingData(query: KeyValue) {
    const service: ApiServiceBase = new ApiService(ServiceType.TaxFiling);
    return service.get({ query });
  }
  public refreshTaxFilingData(query: {}) {
    const service: ApiServiceBase = new ApiService(ServiceType.TaxFiling);
    return service.get({ route: ["refresh"], query });
  }
}

const taxFilingService = new TaxFilingService();
export default taxFilingService;
