import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "./store/store";

const originalSetItem = window.localStorage.setItem;

/**
 * Overriding default behavior of setItem,removeItem, clear of
 * localstorage, triggers storageModified event.
 * Now we can keep track if a key in LocalStorage changed within a
 * TAB as well as in a different TAB.
 */
window.localStorage.setItem = function () {
  const event = new Event("storageModified");
  originalSetItem.apply(this, arguments as any);
  window.dispatchEvent(event);
};

const originalRemoveItem = window.localStorage.removeItem;

window.localStorage.removeItem = function () {
  const event = new Event("storageModified");
  originalRemoveItem.apply(this, arguments as any);
  window.dispatchEvent(event);
};
const originalClear = window.localStorage.clear;

window.localStorage.clear = function () {
  const event = new Event("storageModified");
  originalClear.apply(this, arguments as any);
  window.dispatchEvent(event);
};

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
