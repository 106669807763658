import ApiServiceBase from "../ApiServiceBase";
import ApiService from "../ApiService";
import { ServiceType } from "../ServiceType.data";
import { Progress, UniqueFile } from "../../store/state/FileUploadState";

export class FileUploadService {
  private readonly service: ApiServiceBase = new ApiService(
    ServiceType.FileUpload
  );

  public uniqueFileCheck(data: string) {
    return this.service.get({
      route: ["handle-duplicate"],
      query: { file_name: data }
    });
  }

  public postFile(
    data: UniqueFile,
    updateProgress: (progress: Progress) => void
  ) {
    return this.service.postMultipart(
      { route: ["upload-file"] },
      {
        file: data.file,
        description: data.description,
        data_source_name: data.dataSource
      },
      updateProgress
    );
  }
}

const fileUploadService = new FileUploadService();
export default fileUploadService;
