export enum KyssActionTypes {
  GET_REPORT_BY_DATE = "GET_REPORT_BY_DATE",
  GET_REPORT_BY_DATE_SUCCESS = "GET_REPORT_BY_DATE_SUCCESS",
  GET_REPORT_BY_DATE_ERROR = "GET_REPORT_BY_DATE_ERROR",
}

export interface KyssAction {
    type: KyssActionTypes;
    data: any;
}

export class KyssActions {
    public static GetKyssReport(fromDate: string, toDate: string): KyssAction {
      return { type: KyssActionTypes.GET_REPORT_BY_DATE, data: {fromDate, toDate} };
    }
    
  public static GetKyssReportSuccess(data: any): KyssAction {
    return { type: KyssActionTypes.GET_REPORT_BY_DATE_SUCCESS, data };
  }
  public static GetKyssReportError(data: Error): KyssAction {
    return { type: KyssActionTypes.GET_REPORT_BY_DATE_ERROR, data };
  }
}