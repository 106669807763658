import { useMemo } from "react";
import { Dispatch, AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";

import { LoginActions } from "../../actions/LoginActions";
import { ApplicationState } from "../../RootReducers";
import { LoginState } from "../../state/LoginState";

interface Selectors {
  loginState: LoginState;
}
interface ActionDispatchers {
  logout: () => void;
}

/**
 * accesses Redux, returns required state,actions
 */
const useLogout = (): Selectors & ActionDispatchers => {
  const dispatch = useDispatch<Dispatch<AnyAction>>();
  const selectors = useSelector(stateSelector);
  const actionDispatchers = useMemo(() => actions(dispatch), [dispatch]);

  return { ...selectors, ...actionDispatchers };
};

const stateSelector = (state: ApplicationState): Selectors => ({
  loginState: state.login
});

const actions = (dispatch: Dispatch<AnyAction>): ActionDispatchers => ({
  logout: () => {
    dispatch(LoginActions.LogoutRequest());
  }
});

export default useLogout;
