import get from "lodash/get";
import { useSelector } from "react-redux";

import { LoginState } from "../../state/LoginState";
import { ApplicationState } from "../../RootReducers";
import { UserPermissions } from "constants/UserPermissions";

interface Selectors {
  loginState: LoginState;
}
/**
 * returns all permissions user has
 */
const useUserPermissions = (): UserPermissions[] => {
  const {
    loginState: { accessDetails }
  } = useSelector(stateSelector);
  const permissions = get(
    accessDetails,
    "permissions",
    []
  ) as UserPermissions[];

  return permissions;
};

const stateSelector = (state: ApplicationState): Selectors => ({
  loginState: state.login
});

export default useUserPermissions;
