import { LoginRequest } from "../../store/actions/LoginActions";
import ApiServiceBase from "../ApiServiceBase";
import ApiService from "../ApiService";
import { ServiceType } from "../ServiceType.data";

export class LoginService {
  private readonly service: ApiServiceBase = new ApiService(ServiceType.Auth);
  public login(data: LoginRequest) {
    return this.service.post({ route: ["auth-token"] }, data);
  }

  public logout() {
    return this.service.get({ route: ["logout"] });
  }
}

const loginService = new LoginService();
export default loginService;
