import * as axios from "axios";

import { message } from "antd";

import { AppSettings } from "../AppSettings";
import AxiosInstance from "./AxiosInstance";
import { HttpContentType } from "./HttpContentType";
import { QueryPath } from "./QueryPath.data";
import { ServiceType } from "./ServiceType.data";
import { Progress } from "../store/state/FileUploadState";
import LocalStorageKeys from "../constants/LocalStorageConstants";
import { API_ERROR_FEEDBACK } from "../Constants";
import localStorage from "./LocalStorage";

export interface ParsedError {
  message: string;
  result?: any;
}

/// <summary>
/// ApiServiceMock cannot inherit ApiService, because that's mocked and that would create an infinite loop, that's why we need ApiServiceBase.
/// </summary>
export default abstract class ApiServiceBase {
  protected readonly serviceType: ServiceType;
  protected readonly hideFeedback: boolean;

  constructor(serviceType: ServiceType, hideFeedback?: boolean) {
    this.serviceType = serviceType;
    this.hideFeedback = !!hideFeedback;
  }

  public abstract get<T = void>(
    path: QueryPath,
    isBlob?: boolean,
    contentType?: HttpContentType
  ): Promise<T> | T;
  public abstract post<T = void>(path: QueryPath, body: any): Promise<T> | T;
  public abstract put<T = void>(path: QueryPath, body: any): Promise<T> | T;
  public abstract patch<T = void>(path: QueryPath, body: any): Promise<T> | T;
  public abstract postMultipart<T = void>(
    path: QueryPath,
    data: any,
    updateProgress?: (progress: Progress) => void
  ): Promise<T> | T;

  public provideErrorFeedback(content: string, duration?: number) {
    if (!this.hideFeedback) {
      message.error({ content, duration });
    }
  }

  // tslint:disable-next-line: cyclomatic-complexity
  public processError(error: any): ParsedError {
    const errorCode = error.response ? error.response.status || 500 : 500;
    switch (errorCode) {
      case 401:
        localStorage.clearAll();

        //to clear redux store
        window.location.reload();
        return { message: "Unauthorized" };
      case 404:
        this.provideErrorFeedback(API_ERROR_FEEDBACK.error);
        return { message: "The request is not found" };
      case 500:
        this.provideErrorFeedback(API_ERROR_FEEDBACK.error);
        return { message: "Internal server error" };
      case 400:
      case 403:
      case 412:
      case 422:
      case 423:
      case 424: {
        const err = error.response.data;

        if (err) {
          if (err.message && err.message.isJson) {
            //if "isJson" flag is set, returning the error object, stringified
            delete err.message.isJson;
            return new Error(JSON.stringify(err.message, null, 4));
          } else if (err.message) {
            //showing feedback to user about the error if message is string
            this.provideErrorFeedback(
              typeof err.message === "string"
                ? err.message
                : API_ERROR_FEEDBACK.error
            );
            //Just to be safe, prevent runtime error if it is not string.
            return {
              message:
                typeof err.message === "string"
                  ? err.message
                  : JSON.stringify(err.message),
              result: err.result || ""
            };
          }
        }
        return { message: "Error" };
      }
    }
    return error;
  }

  /* tslint:enable */
  //It returns Headers to make API call
  protected getConfig(contentType: HttpContentType): axios.AxiosRequestConfig {
    const token = localStorage.getItem(LocalStorageKeys.AuthToken);
    const headers = {
      "Content-Type": contentType.toString(),
      Authorization: token && `Token ${token}`
    };
    return { headers };
  }

  protected getUploadConfig(
    contentType: HttpContentType,
    onUploadProgress: (progressEvent: any) => void
  ): axios.AxiosRequestConfig {
    const token = localStorage.getItem(LocalStorageKeys.AuthToken);
    const config: axios.AxiosRequestConfig = {
      headers: {
        "Content-Type": contentType.toString(),
        Authorization: token && `Token ${token}`
      },
      onUploadProgress
    };
    return config;
  }

  protected getAxiosInstance(): axios.AxiosInstance {
    const instance = AxiosInstance.create();
    return instance;
  }

  /// Generates url: {AppSettings.service.baseUrl}/{this.serviceType}/{routeParam1}/{routeParam2}/.../{routeParamN}?{queryParam1key}={queryParam1val}&{queryParam2key}={queryParam2val}...
  /// Need this to be able to write the mocks properly. Don't want to parse urls.
  /// Query params with null, undefined or empty string won't be appended to the url.

  protected getUrl(path: QueryPath): string {
    const baseUrl = AppSettings.server.baseUrl;
    let url: string = `${baseUrl}/${this.serviceType}`;

    if (path) {
      if (path.route && path.route.length > 0) {
        for (const route of path.route) {
          if (route && route !== "undefined") {
            url += `/${route}`;
          }
        }
      }
      if (path.query) {
        let separator = "?";
        for (const name in path.query) {
          if (path.query[name]) {
            url += `${separator}${encodeURI(name)}=${encodeURIComponent(
              path.query[name]!.toString()
            )}`;
            separator = "&";
          }
        }
      }
    }
    return url;
  }
}
