import { color, fonts, fontSizes } from "../../theme/Theme";

import { css } from "@emotion/core";

export const sidebarStyle = (Collapsed: boolean) => css`
  .root {
    overflow: auto;
    height: 100vh;
    position: sticky;
    left: 0;
    background: ${color.white};
    display: flex;
    overflow: hidden;
  }
  .profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }
  .profile-name {
    font-family: ${fonts.ProximaNovaSemibold};
    font-size: ${fontSizes.text};
    font-weight: bold;
    line-height: 1.21;
    color: ${color.black};

    margin: 10px 0px;
  }
  .profile-role {
    font-family: ${fonts.ProximaNovaRegular};
    font-size: ${fontSizes.smallText};
    line-height: 1.25;

    text-transform: capitalize;
  }
  .header {
    background-color: ${color.light};
    display: flex;
    padding: 19px 15px;
  }
  .header-menu-icon {
    margin: ${Collapsed ? "auto" : undefined};
  }
  .jitfin-header-logo {
    margin: auto;
    display: ${Collapsed ? "none" : undefined};
  }
  .link-style {
    margin-left: ${Collapsed ? 27 : 15}px;
    font-size: ${fontSizes.text};
    font-family: ${fonts.ProximaNovaSemibold};
    font-weight: 600;
  }
  .logout-container {
    background-color: ${color.logoutBackground} !important;
    margin: 0px;
    opacity: 0.5;

    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .logout-image {
    object-fit: contain;
  }

  .logout-text {
    font-family: ${fonts.ProximaNovaBold};
    font-size: ${fontSizes.subTitle};
    line-height: 1.25;
    color: ${color.white};
    margin-left: ${Collapsed ? 27 : 15}px;
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    width: inherit;
  }

  .kyss-menuitem {
    padding: 0 24px !important;
    .link-style {
      margin-left: 0;
    }
  }
`;
