import { takeEvery, put } from "redux-saga/effects";
import {
  ResetPasswordActionTypes,
  ResetPasswordAction,
  ResetPasswordActions
} from "../../store/actions/ResetActions";

import ResetPasswordService from "../../services/authService/ResetService";
import { notification } from "antd";
import { ResetPasswordPayload } from "../../services/authService/ResetService.data";

function* ForgotPasswordWorker(
  action: ResetPasswordAction
): IterableIterator<any> {
  try {
    yield ResetPasswordService.forgetPassword(action.data as string);
    yield put(ResetPasswordActions.forgetPasswordSuccess());

    notification.success({
      message: "An Email with the Steps to Reset Password is Successfully sent!"
    });
  } catch (error) {
    yield put(ResetPasswordActions.forgetPasswordError(error.message || error));
  }
}

function* ResetPasswordWorker(
  action: ResetPasswordAction
): IterableIterator<any> {
  try {
    const data = action.data as ResetPasswordPayload;
    yield ResetPasswordService.resetPassword({ ...data, redirect: undefined });
    yield put(ResetPasswordActions.resetPasswordSuccess());
    if (data.redirect) {
      data.redirect();
    }
    notification.success({ message: "Password has been reset Successfully!!" });
  } catch (error) {
    yield put(ResetPasswordActions.resetPasswordError(error.message || error));
  }
}

function* ResetPasswordWatcher(): IterableIterator<any> {
  yield takeEvery(ResetPasswordActionTypes.forgotPasswordRequest, action =>
    ForgotPasswordWorker({
      type: action.type,
      data: (action as ResetPasswordAction).data
    })
  );

  yield takeEvery(ResetPasswordActionTypes.resetPasswordRequest, action =>
    ResetPasswordWorker({
      type: action.type,
      data: (action as ResetPasswordAction).data
    })
  );
}

export default ResetPasswordWatcher;
