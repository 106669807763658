import { css } from "@emotion/core";

export const color = {
  primary: "#007733",
  secondary: "#b6ca3b",
  yellowOrange: "#e0f46a80",
  navigationBackground: "#f4f4f45f",
  light: "#00973e",
  lightGreen: "#52c41a",
  kellyGreen: "#CCEAD8",
  melon: "#F6ADAD",
  transparentLight: "#43a04791",
  success: "#43a047",
  error: "#D33730",
  darkishGreen: "#2e885a",
  darkRed: "#eb5050",
  red: "#f1553c",
  info: "#1976D1",
  warning: "#ffa000",
  orange: "#f5a02c",
  white: "#ffffff",
  shadedWhite: "#eaeaea",
  grey: "#7E7E7E",
  lightGrey: "#eaeaea",
  darkGrey: "#072439",
  lightSilver: "#bfbfbf",
  actionGrey: "#6c6c6c21",
  grayish: "#2b3c5a",
  flowerBlue: "#41436f",
  pepperMint: "#d7dae2",
  black: "#000000",
  darkSilver: "#d9d9d9",
  darkGreen: "#2d885a",
  logoutBackground: "#182326",
  transparentBlack: "#0000004d",
  transparentBlack1: "#000000cc",
  transparentBlack2: "#00000029",
  transparentBlack3: "#00000080",
  transparentBlack4: "#00000010",
  background: "#f9f9f9",
  fadedOrange: "#ef6d6d",
  activeBackground: "#f1f5f3",
  darkText: "#525252",
  border: "#d9d9d9",
  boxShadow: "rgba(0, 0, 0, 0.1)",
  boxShadowDark: "rgba(0, 0, 0, 0.16)",
  lightBlue: "#2d72d3",
  fiord: "#585c5f",
  darkerGrey: "#808080",
  translucentLightGrey: "#52525233",
  gold: "#e08100"
};

export const fonts = {
  ProximaNovaRegular: "Proxima Nova Regular",
  ProximaNovaBlack: "Proxima Nova Black",
  ProximaNovaBold: "Proxima Nova Bold",
  ProximaNovaLight: "Proxima Nova Light",
  ProximaNovaExtrabold: "Proxima Nova Extrabold",
  ProximaNovaSemibold: "Proxima Nova Semibold",
  ProximaNovaThin: "Proxima Nova Thin"
};

export const fontSizes = {
  header: "24px",
  title: "24px",
  subTitle: "20px",
  buttonText: "18px",
  cardLabel: "18px",
  label: "16px",
  text: "14px",
  smallText: "12px",
  error: "12px"
};

export const breakpoints = {
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px"
};

//phase-2 styles
export const theme = css({
  body: {
    fontFamily: fonts.ProximaNovaRegular
  },
  ".text-capitalize": {
    textTransform: "capitalize"
  },

  ".ant-typography": {
    color: color.darkGrey
  },
  ".error, .ant-typography.error": {
    color: color.red,
    textAlign: "right",
    clear: "right",
    fontSize: fontSizes.error
  },
  label: {
    fontSize: fontSizes.smallText,
    fontFamily: fonts.ProximaNovaRegular,
    color: color.darkGrey
  },
  ".downloadable-link": {
    color: color.lightBlue
  },

  //tags
  ".ant-tag-ellipse": {
    background: color.white,
    borderRadius: "1.25rem"
  },
  ".ant-tag-grey": {
    color: color.darkGrey,
    border: `1px solid ${color.darkGrey}`
  },
  ".ant-tag-green": {
    color: color.secondary,
    border: `1px solid ${color.primary}`
  },
  ".ant-tag-red": {
    color: color.red,
    border: `1px solid ${color.red}`
  },
  ".ant-tag-name-ellipsis": {
    width: "90%",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },

  //Table Styles
  ".ant-table-header": {
    background: "unset"
  },
  ".ant-table-thead > tr, .ant-table-row": {
    th: {
      color: color.darkText,
      backgroundColor: color.activeBackground,
      fontFamily: fonts.ProximaNovaBold,
      fontSize: fontSizes.text
    },
    td: { color: color.darkText },
    "th, td": {
      border: "none",
      padding: "0.75rem 1rem",
      textAlign: "center"
    },
    "th:first-of-type, td:first-of-type": {
      borderRadius: "0.5rem 0 0 0.5rem"
    },
    "th:last-child, td:last-child": {
      borderRadius: "0 0.5rem 0.5rem 0"
    }
  },
  ".ant-table-tbody > .odd-table-row > td.ant-table-column-sort ": {
    background: color.activeBackground
  },
  ".odd-table-row > td": {
    backgroundColor: color.activeBackground
  },

  //divider
  ".ant-divider-vertical.ant-divider-dashed": {
    borderWidth: "0px 0px 10px 2px"
  },

  // Searchbar styling.
  ".searchbar": {
    width: "18.75rem",
    ".ant-input": {
      borderRadius: "1rem",
      fontSize: fontSizes.smallText
    }
  },

  // Pagination styling.
  ".ant-pagination": {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "flex-end"
  }
});
