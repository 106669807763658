import ApiServiceBase from "../../services/ApiServiceBase";
import ApiService from "../../services/ApiService";
import { ServiceType } from "../../services/ServiceType.data";
import { QueryPath } from "../../services/QueryPath.data";
import {
  DownloadServiceResponse,
  DownloadEligibilityFolderRequest
} from "./DownloadService.type";

export class DownloadService {
  public downloadFailedRecords(query: QueryPath) {
    const service: ApiServiceBase = new ApiService(ServiceType.FailedRecord);

    return service.get(query);
  }

  public downloadFile(fileID: number) {
    const service: ApiServiceBase = new ApiService(ServiceType.ParsedCSVRecord);

    return service.get({
      route: ["download"],
      query: { file_id: fileID }
    });
  }

  public downloadEligibilityDocument(
    key: string
  ): DownloadServiceResponse | Promise<DownloadServiceResponse> {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);

    return service.get({
      route: ["download-link"],
      query: {
        s3_key: key
      }
    });
  }

  public downloadEligibilityFolder(
    data: DownloadEligibilityFolderRequest
  ): DownloadServiceResponse | Promise<DownloadServiceResponse> {
    const service: ApiServiceBase = new ApiService(ServiceType.Eligibility);

    return service.post(
      {
        route: ["download-link"]
      },
      data
    );
  }
}

const downloadService = new DownloadService();
export default downloadService;
