import {
  DataSourceState,
  DataSource,
  Fields,
  CreateDataSourceError
} from "../../store/state/DataSourceState";

import {
  DataSourceAction,
  DataSourceActionTypes
} from "../../store/actions/DataSourceActions";

export const initialState: DataSourceState = {
  loading: false,
  dataSourceList: undefined,
  fields: [],
  createDataSourceError: undefined,
  posting: false,
  updatingFields: false,
  taxFilingData: { refreshing: false },
  isDataSourcePolling: false
};

// tslint:disable-next-line: cyclomatic-complexity
export const DataSourceReducer = (
  state = initialState,
  action: DataSourceAction
): DataSourceState => {
  switch (action.type) {
    case DataSourceActionTypes.POLL_TAX_FILING_SUCCESS:
      return {
        ...state,
        isDataSourcePolling: action.data as boolean
      };
    case DataSourceActionTypes.POLL_TAX_FILING_ERROR:
      return {
        ...state,
        isDataSourcePolling: false
      };

    case DataSourceActionTypes.DATA_SOURCE_REQUEST:
      //NOTE: don't make dataSourceList undefined!!
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case DataSourceActionTypes.DATA_SOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        dataSourceList: action.data as DataSource[]
      };
    case DataSourceActionTypes.DATA_SOURCE_BATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        dataSourceList: [
          ...(state.dataSourceList || []), //if there is previous data, adding it
          ...(action.data as DataSource[]) //Appending new page of data with old
        ]
      };
    case DataSourceActionTypes.DATA_SOURCE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data
      };

    case DataSourceActionTypes.GET_FIELDS_REQUEST:
      return {
        ...state,
        updatingFields: true,
        fields: [],
        error: undefined
      };
    case DataSourceActionTypes.GET_FIELDS_SUCCESS:
      return {
        ...state,
        updatingFields: false,
        error: undefined,
        fields: action.data as Fields[]
      };
    case DataSourceActionTypes.GET_FIELDS_ERROR:
      return {
        ...state,
        updatingFields: false,
        error: action.data
      };

    case DataSourceActionTypes.POST_DATA_SOURCE_REQUEST:
      return {
        ...state,
        posting: true,
        error: undefined
      };
    case DataSourceActionTypes.POST_DATA_SOURCE_SUCCESS:
      return {
        ...state,
        posting: false,
        error: undefined
      };
    case DataSourceActionTypes.POST_DATA_SOURCE_ERROR:
      return {
        ...state,
        posting: false,
        createDataSourceError: action.data as CreateDataSourceError
      };

    case DataSourceActionTypes.DATA_SOURCE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
        fields: []
      };
    case DataSourceActionTypes.DATA_SOURCE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        fields: action.data as Fields[]
      };
    case DataSourceActionTypes.DATA_SOURCE_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data
      };
    case DataSourceActionTypes.SET_DATA_SOURCE_COUNT:
      return { ...state, dataSourceListSize: action.data as number };

    case DataSourceActionTypes.REFRESH_TAX_FILING_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        taxFilingData: { ...state.taxFilingData, refreshing: true },
        error: undefined,
        isDataSourcePolling: false
      };

    case DataSourceActionTypes.REFRESH_TAX_FILING_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        taxFilingData: { ...state.taxFilingData, refreshing: false },
        isDataSourcePolling: true
      };

    case DataSourceActionTypes.REFRESH_TAX_FILING_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data,
        taxFilingData: { ...state.taxFilingData, refreshing: false }
      };

    default:
      return state;
  }
};
