import {
  EligibilityDetails,
  ApplicantDetails,
  Receivable
} from "../../services/eligibilityService/EligibilityService.type";
import { EligibilityDetailsState } from "./state";
import { MultiTags } from "../../components/formHelpers/FormMultiInputTags";

const applicantDetailsInitialState: ApplicantDetails = {
  firstName: null,
  middleName: null,
  lastName: null,
  currentAddress: "",
  currentAddressType: null,
  dateOfBirth: null,
  isPermanentAddressSameAsCurrentAddress: false,
  panNumber: null,
  permanentAddress: null,
  permanentAddressType: null
};

export const toMultiTags = (data: Array<{ gstin: string }>): MultiTags[] =>
  data?.map((item, index) => ({
    id: index,
    value: item.gstin,
    removed: false,
    loading: false
  }));

const receivablesDetailsInitialState: Receivable[] = [
  {
    gstin: null,
    receivableAmountForNextFourMonths: null,
    status: null,
    tradeName: null
  }
];

export const subIndustryOthers = "Others";

export const formatEligibilityDetails = (
  data: EligibilityDetails
): EligibilityDetailsState => {
  const {
    applicantDetails,
    reason: rejectReason,
    isRejectReasonManual,
    eligibilityAmountDetails,
    bankAccount,
    receivablesDetails,
    ...others
  } = data;
  return {
    ...others,
    applicantDetails: applicantDetails
      ? applicantDetails
      : applicantDetailsInitialState,
    receivablesDetails: receivablesDetails.length
      ? receivablesDetails
      : receivablesDetailsInitialState,
    eligibilityAmountDetails: eligibilityAmountDetails
      ? eligibilityAmountDetails
      : {
          eligibilityAmount: null,
          gstinLoginName: null,
          interestRate: null,
          loanCalculationType: null,
          manualGstrDocuments: []
        },

    isRejectReasonManual,
    reason: isRejectReasonManual ? null : rejectReason,
    manualReason: isRejectReasonManual ? rejectReason : null,
    bankAccount: bankAccount || {
      ifscCode: "",
      bankName: "",
      accountNumber: ""
    }
  };
};
