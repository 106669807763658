import { Action } from "typescript-fsa";
import { takeEvery, put, takeLatest } from "redux-saga/effects";
import { notificationActions } from "./actions";
import { NotificationService } from "../../services/notification";
import { NotificationListPayload } from "../../services/notification/NotificationService.type";

function* GetAllNotificationsWorker(_: Action<void>) {
  try {
    const response: NotificationListPayload = yield NotificationService.getAllNotifications();

    yield put(
      notificationActions.getAllNotifications.done({
        result: response.notificationList.reverse()
      })
    );
  } catch (error) {
    yield put(notificationActions.getAllNotifications.failed(error));
  }
}

function* GetNewNotificationsWorker(_: Action<void>) {
  try {
    const response: NotificationListPayload = yield NotificationService.getNewNotifications();

    yield put(
      notificationActions.getNewNotifications.done({
        result: response.notificationList
      })
    );
  } catch (error) {
    yield put(notificationActions.getNewNotifications.failed(error));
  }
}

function* CloseNotificationWorker(action: Action<number>) {
  try {
    yield NotificationService.closeNotification(action.payload);

    yield put(
      notificationActions.closeNotification.done({
        params: action.payload
      })
    );
  } catch (error) {
    yield put(notificationActions.closeNotification.failed(error));
  }
}

function* NotificationWatcher() {
  yield takeEvery(
    notificationActions.getAllNotifications.started,
    GetAllNotificationsWorker
  );
  yield takeLatest(
    notificationActions.getNewNotifications.started,
    GetNewNotificationsWorker
  );
  yield takeEvery(
    notificationActions.closeNotification.started,
    CloseNotificationWorker
  );
}

export default NotificationWatcher;
