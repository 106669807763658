import { NotificationState } from "./state";
import { NotificationMessage } from "../../services/notification/NotificationService.type";

export const getUpdatedNotifications = (
  state: NotificationState,
  newMessages: NotificationMessage[]
): NotificationMessage[] => {
  const previousNotifications = state.notificationMessages;

  let currentNotifications: NotificationMessage[] = [];

  newMessages.forEach((notification) => {
    const index = previousNotifications.findIndex(
      (item) => item.id === notification.id
    );

    if (index !== -1) {
      //update notification if already present
      previousNotifications[index] = notification;
    } else {
      //push new notifications
      currentNotifications.push(notification);
    }
  });

  currentNotifications = [
    ...currentNotifications.reverse(),
    ...previousNotifications
  ];

  //remove non active notifications, order new notifications to come first
  return currentNotifications.filter((item) => item.isActive);
};

export const closeNotification = (
  notifications: NotificationMessage[],
  id: number
) => notifications.filter((item) => item.id !== id);
