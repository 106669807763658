import actionCreatorFactory from "typescript-fsa";
import { EligibilityDetailsState } from "./state";
import {
  ResponseWithStatus,
  EligibilityListProp
} from "../../services/eligibilityService/LoanStatus.type";

export interface InitiateDMIWorkflow {
  eligibilityId: string;
  onSuccess?: () => void;
}

export type UpdateStatus = Partial<
  Pick<ResponseWithStatus, "status" | "subStatus">
>;

const actionCreator = actionCreatorFactory();

const eligibilityDetailsActions = actionCreator.async<
  string,
  EligibilityDetailsState,
  Error
>("ELIGIBILITY_DETAILS");

const getSubIndustriesActions = actionCreator.async<string, string[], string>(
  "GET_SUB_INDUSTRIES"
);

const updateEligibilityDataActions = actionCreator<
  Partial<EligibilityDetailsState>
>("UPDATE_ELIGIBILITY_DATA");

const updateStatusAction = actionCreator<UpdateStatus>("UPDATE_STATUS");

const updateFilterStatus = actionCreator<EligibilityListProp>(
  "UPDATE_ELIGIBILITY_LIST_FILTER"
);

export const eligibilityActions = {
  eligibilityDetails: eligibilityDetailsActions,
  getSubIndustries: getSubIndustriesActions,
  updateEligibilityData: updateEligibilityDataActions,
  updateStatus: updateStatusAction,
  updateFilterStatus
};
