/** @jsx jsx */
import React, { useState, useCallback, useMemo } from "react";

import { jsx } from "@emotion/core";

import { Link } from "react-router-dom";

import { Icon, Layout, Menu, Avatar, Divider } from "antd";

import jitfinLogo from "../../assets/jitfin.svg";
import LogOutIcon from "../../assets/log_out.svg";

import {
  sidebarItems,
  sidebarRoutes,
  SidebarComponentLabels as LABELS
} from "./SidebarComponentConstants";
import { sidebarStyle } from "./SidebarComponentStyles";

import useRedirect from "../../helperHooks/useRedirect";
import useLogout from "../../store/hooks/auth/useLogout";
import useProfileDetails from "../../store/hooks/auth/useProfileDetails";
import useUserPermissions from "../../store/hooks/auth/useUserPermissions";
import useFeatureFlags from "../../hooks/features/useFeatureFlags";

const { Sider } = Layout;
const { SubMenu } = Menu;

/**
 * SidebarComponent, which can expand, shrink and redirect to other pages
 */
const SidebarComponent: React.FC = () => {
  const [Collapsed, setCollapsed] = useState(true);
  const onCollapse = () => setCollapsed(!Collapsed);

  const redirect = useRedirect();
  const { logout } = useLogout();
  const handleLogOut = useCallback(() => {
    logout();
  }, [logout]);

  const { role, userDetails } = useProfileDetails();
  const userPermissions = useUserPermissions();
  const { enableEligibilityList } = useFeatureFlags();
  const enableKYSS = true;

  const { clientManagementRoutes, prospectingRoutes } = useMemo(
    () => sidebarRoutes(userPermissions),
    [userPermissions]
  );

  return (
    <div css={sidebarStyle(Collapsed)}>
      <Sider
        width={270} //not possible via style
        className="root"
        collapsible
        collapsed={Collapsed}
        trigger={null}
        onCollapse={onCollapse}
      >
        <div className="header">
          <Icon type="menu" onClick={onCollapse} className="header-menu-icon" />
          <img src={jitfinLogo} alt="jitfin" className="jitfin-header-logo" />
        </div>

        {!Collapsed && userDetails && (
          <React.Fragment>
            <div className="profile-container">
              <Avatar
                size="large"
                src={userDetails.profile_url}
                className="profile-image"
                icon="user"
              />

              <div className="profile-name">{userDetails.username}</div>
              <div className="profile-role">{role}</div>
            </div>
            <Divider />
          </React.Fragment>
        )}
        <Menu
          defaultSelectedKeys={[sidebarItems.ClientManagement.name]}
          mode="inline"
        >
          {enableEligibilityList && (
            <Menu.Item
              key={sidebarItems.Dashboard.name}
              onClick={() => redirect(() => sidebarItems.Dashboard.route)}
            >
              <span>
                <img src={sidebarItems.Dashboard.icon} alt="Dashboard" />
                <Link to={sidebarItems.Dashboard.route} className="link-style">
                  <span>{sidebarItems.Dashboard.name}</span>
                </Link>
              </span>
            </Menu.Item>
          )}

          {clientManagementRoutes.length && (
            <SubMenu
              key={sidebarItems.ClientManagement.name}
              title={
                <span>
                  <img
                    src={sidebarItems.ClientManagement.icon}
                    alt="client management"
                  />
                  <span>
                    <Link
                      to={sidebarItems.ClientManagement.route}
                      className="link-style"
                    >
                      {sidebarItems.ClientManagement.name}
                    </Link>
                  </span>
                </span>
              }
            >
              {clientManagementRoutes.map((item) => (
                <Menu.Item key={item.name}>
                  <Link to={item.route} className="link-style">
                    {item.name}
                  </Link>
                </Menu.Item>
              ))}
            </SubMenu>
          )}
          {prospectingRoutes.length && (
            <SubMenu
              key={sidebarItems.prospecting.name}
              title={
                <span>
                  <img src={sidebarItems.prospecting.icon} alt="prospecting" />
                  <span>
                    <Link
                      to={sidebarItems.prospecting.route}
                      className="link-style"
                    >
                      {sidebarItems.prospecting.name}
                    </Link>
                  </span>
                </span>
              }
            >
              {prospectingRoutes.map((item) => (
                <Menu.Item key={item.name}>
                  <Link to={item.route} className="link-style">
                    {item.name}
                  </Link>
                </Menu.Item>
              ))}
            </SubMenu>
          )}
          {enableKYSS && (
            <Menu.Item
              key={sidebarItems.KYSS.name}
              onClick={() => redirect(() => sidebarItems.KYSS.route)}
              className="kyss-menuitem"
            >
              <span>
                <Link to={sidebarItems.KYSS.route} className="link-style">
                  <img src={sidebarItems.KYSS.icon} alt="KYSS" />
                  {/* <span>{sidebarItems.KYSS.name}</span> */}
                </Link>
              </span>
            </Menu.Item>
          )}
          <Menu.Item className="logout-container" onClick={handleLogOut}>
            <Icon
              viewBox="0 0 30 30"
              component={() => (
                <img src={LogOutIcon} alt="logout" className="logout-image" />
              )}
            ></Icon>
            <span className="logout-text">{LABELS.logout}</span>
          </Menu.Item>
        </Menu>
      </Sider>
    </div>
  );
};

export default SidebarComponent;
