export enum UserActivityRoutes {
  eligibility = "eligibility",
  application = "application",
  bills = "bills",
  documents = "documents"
}

export enum AddProspectRoutes {
  addDataSource = "select-data-source",
  createProspect = "create-prospect"
}

export enum AnchorRoutes {
  create = "create",
  list = "list"
}

export const routes = {
  login: {
    new: "/login"
  },
  forgetPassword: {
    new: "/forget-password"
  },
  resetPassword: {
    new: "/reset-password",
    view: (token: string) => `/reset-password/${token}`
  },
  files: {
    new: "/files",
    view: (fileID: string | number) => `/files/${fileID}`
  },
  home: {
    new: "/home"
  },
  prospects: {
    new: "/prospects",
    add: "/add-prospect",
    addView: (step: string) => `/add-prospect/${step}`,
    view: (fileID: string | number) => `/prospects/${fileID}`
  },
  newFileUpload: {
    new: "/new-file-upload"
  },
  prospectDetailedView: {
    new: "/prospect-details",
    view: (prospectID: string) => `/prospect-details/${prospectID}`
  },
  dataSourceView: {
    new: "/data-source",
    view: (dataSourceID: string | number) => `/data-source/${dataSourceID}`,
    create: "/data-source/create"
  },
  eligibilityListView: {
    new: "/client-list"
  },
  challanListView: {
    new: "/challans",
    view: (eligibilityId: string) => `/challans/${eligibilityId}`
  },
  anchor: {
    config: `/anchor/:anchorTab`,
    view: (anchorTab: AnchorRoutes) => `/anchor/${anchorTab}`
  },
  userActivity: {
    new: "/user-activity", //for list of eligibility ID's

    config: "/user-activity/:eligibilityId/:activity",
    view: (eligibilityId: string) =>
      `${routes.userActivity.new}/${eligibilityId}`,

    activityView: (eligibilityId: string, activity: UserActivityRoutes) =>
      `${routes.userActivity.view(eligibilityId)}/${activity}`, //generates UserActivityRoutes

    eligibility: {
      config: `/user-activity/:eligibilityId/${UserActivityRoutes.eligibility}/:eligibilityFold`,

      //config for sub-routes
      viewConfig: (eligibilityFold: EligibilityRoutes) =>
        `/user-activity/:eligibilityId/${UserActivityRoutes.eligibility}/${eligibilityFold}`,

      view: (eligibilityId: string, eligibilityFold: EligibilityRoutes) =>
        `${routes.userActivity.activityView(
          eligibilityId,
          UserActivityRoutes.eligibility
        )}/${eligibilityFold}`
    },
    documents: {
      config: `/user-activity/:eligibilityId/${UserActivityRoutes.documents}/:documentsTab`,
      viewConfig: (documentsTab: DocumentsRoutes) =>
        `/user-activity/:eligibilityId/${UserActivityRoutes.documents}/${documentsTab}`,

      view: (eligibilityId: string, documentsTab: DocumentsRoutes) =>
        `${routes.userActivity.activityView(
          eligibilityId,
          UserActivityRoutes.documents
        )}/${documentsTab}`
    },
    bills: {
      config: `/user-activity/:eligibilityId/${UserActivityRoutes.bills}`
    },

    application: {
      config: `/user-activity/:eligibilityId/${UserActivityRoutes.application}/:applicationTab`,
      viewConfig: (applicationTab: ApplicationRoutes) =>
        `/user-activity/:eligibilityId/${UserActivityRoutes.application}/${applicationTab}`,

      view: (eligibilityId: string, applicationTab: ApplicationRoutes) =>
        `${routes.userActivity.activityView(
          eligibilityId,
          UserActivityRoutes.application
        )}/${applicationTab}`
    }
  },
  kyss:{
    new: `/kyss`,
    view: (dateperiod: string) => `/kyss/${dateperiod}`
  },
  capexUserActivity: {
    new: "/capex-user-activity",
    eligibility: {
      config: `/capex-user-activity/:eligibilityId/:eligibilityFold`,

      //config for sub-routes
      view: (eligibilityId: string, eligibilityFold: CapexEligibilityRoutes) =>
          `${routes.capexUserActivity.new}/${eligibilityId}/${eligibilityFold}`,
    }
  },
};

export enum CapexEligibilityRoutes {
  Details = "details",
  EligibilityAndKyc = "eligibility-kyc",
  Documents = "documents",
  Disbursement = "disbursement",
}

export enum EligibilityRoutes {
  CompanyFold = "company-details",
  ApplicantFold = "applicant-details",
  EligibilityAmountFold = "eligibility-amount-details",
  AcceptRejectFold = "eligibility-action"
}

export enum ApplicationRoutes {
  KYC = "kyc-documents-status",
  AccountCreation = "account-creation-status",
  ReceivablesConfirmation = "receivables-confirmation"
}

export enum DocumentsRoutes {
  List = "list",
  Upload = "upload"
}
