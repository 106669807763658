import get from "lodash/get";

import { useSelector } from "react-redux";

import { UserDetails } from "../../state/LoginState";
import { ApplicationState } from "../../RootReducers";

interface Selectors {
  userDetails?: UserDetails;
  role: string;
}

/**
 * returns userDetails
 */
const useProfileDetails = (): Selectors => {
  return useSelector(stateSelector);
};

const stateSelector = (state: ApplicationState): Selectors => ({
  userDetails: get(state.login, "accessDetails.userDetails"),
  role: get(state.login, "accessDetails.roles[0]", "") //Only first role is displayed on screen
});

export default useProfileDetails;
