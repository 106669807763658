import { takeEvery, takeLatest, put } from "redux-saga/effects";

import { notification } from "antd";

import { NotificationMessage } from "../../constants/NotificationMessages";
import {
  ProspectListAction,
  ProspectListActions,
  ProspectListActionTypes,
  DownloadFile,
  AddProspectRedirect
} from "../../store/actions/ProspectListActions";

import { ProspectPageCount } from "../../Constants";
import ProspectListService from "../../services/prospectListService/ProspectListService";
import DownloadService from "../../services/downloadService/DownloadService";

import {
  Prospect,
  ProspectListState
} from "../../store/state/ProspectListState";
import { QueryPath } from "../../services/QueryPath.data";
import { safeParseJson } from "../../helper/Format";

export function* ProspectListWorker(
  action: ProspectListAction
): IterableIterator<any> {
  try {
    const response: any = yield ProspectListService.getProspects(
      action.data as QueryPath
    );
    const data: ProspectListState = {
      noOfPages: Math.ceil(response.count / ProspectPageCount),
      prospectList: response.results as Prospect[],
      dataSourceName: response.data_source_name,
      fileName: response.file_name,
      loading: false
    };

    yield put(ProspectListActions.getProspectsSuccess(data));
  } catch (error) {
    yield put(ProspectListActions.getProspectsError(error));

    notification.error({
      message: "Unable to Fetch DATA",
      description: error.message || error
    });
  }
}

export function* DownloadFileWorker(
  action: ProspectListAction
): IterableIterator<any> {
  try {
    const { fileID } = action.data as DownloadFile;

    yield DownloadService.downloadFile(fileID);

    notification.info({ message: NotificationMessage.downloadFileInfo });
  } catch (error) {
    yield put(ProspectListActions.downloadFileError());

    notification.error({
      message: "Unable to Download",
      description: error.message || error
    });
  }
}

function* AddProspectWorker(action: ProspectListAction): IterableIterator<any> {
  const data = action.data as AddProspectRedirect;
  try {
    yield ProspectListService.addProspect(data.request);

    yield put(ProspectListActions.addProspectSuccess());
    notification.success({ message: "Prospect Added Successfully" });
    if (data.redirect) {
      data.redirect();
    }
  } catch (error) {
    if (data.setErrors) {
      //Setting errors of form
      data.setErrors(safeParseJson(error.message || {}));
    }
    yield put(ProspectListActions.addProspectError(error.message || error));
  }
}

function* ProspectListWatcher(): IterableIterator<any> {
  yield takeLatest(
    ProspectListActionTypes.getProspectsRequest,
    ProspectListWorker
  );
  yield takeEvery(
    ProspectListActionTypes.downloadFileRequest,
    DownloadFileWorker
  );

  yield takeEvery(
    ProspectListActionTypes.addProspectRequest,
    AddProspectWorker
  );
}

export default ProspectListWatcher;
