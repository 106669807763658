import { LoginState, AccessDetails } from "../../store/state/LoginState";
import {
  LoginAction,
  LoginActionTypes
} from "../../store/actions/LoginActions";

export const initialState: LoginState = {
  loading: false,
  loggedIn: false
};

export const LoginReducer = (
  state = initialState,
  action: LoginAction
): LoginState => {
  switch (action.type) {
    case LoginActionTypes.loginRequest:
      return {
        ...state,
        loading: true
      };

    case LoginActionTypes.loginSuccess:
      return {
        ...state,
        loading: false,
        error: undefined,
        loggedIn: true,
        accessDetails: action.data as AccessDetails
      };
    case LoginActionTypes.loginError:
      return {
        ...state,
        loading: false,
        error: action.data
      };

    case LoginActionTypes.logoutRequest:
      return {
        ...state,
        loading: true
      };
    case LoginActionTypes.logoutSuccess:
      return {
        ...state,
        loading: false,
        error: undefined,
        accessDetails: undefined,
        loggedIn: false
      };
    case LoginActionTypes.logoutError:
      return {
        ...state,
        error: action.data as Error,
        loading: false
      };

    default:
      return state;
  }
};
