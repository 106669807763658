import { takeEvery, put } from "redux-saga/effects";
import {
  LoginActionTypes,
  LoginAction,
  LoginRequest,
  LoginActions
} from "../../store/actions/LoginActions";

import loginService from "../../services/authService/LoginService";
import localStorage from "../../services/LocalStorage";
import LocalStorageKeys from "../../constants/LocalStorageConstants";
import { AccessDetails } from "../../store/state/LoginState";
import { notification } from "antd";

function* LoginWorker(action: LoginAction): IterableIterator<any> {
  try {
    const { password, redirect, username, token } = action.data as LoginRequest;
    const response: any = yield loginService.login({
      username,
      password,
      token
    });
    localStorage.setItem(LocalStorageKeys.AuthToken, response.token as string);
    if (redirect) {
      redirect();
    }

    yield put(LoginActions.LoginSuccess(response as AccessDetails));
  } catch (error) {
    localStorage.removeItem(LocalStorageKeys.AuthToken);
    yield put(LoginActions.LoginError(error.message || error));
  }
}

function* LogoutWorker(_: LoginAction): IterableIterator<any> {
  try {
    yield loginService.logout();

    //clearing all tokens, cache on logout
    localStorage.clearAll();
    sessionStorage.clear();

    notification.success({ message: "You have been Successfully Logged Out!" });

    yield put(LoginActions.LogoutSuccess());
  } catch (error) {
    yield put(LoginActions.LogoutError(error.message || error));
  }
}

function* LoginWatcher(): IterableIterator<any> {
  yield takeEvery(LoginActionTypes.loginRequest, action =>
    LoginWorker({ type: action.type, data: (action as LoginAction).data })
  );

  yield takeEvery(LoginActionTypes.logoutRequest, LogoutWorker);
}

export default LoginWatcher;
