import { ServiceType } from "../ServiceType.data";
import { AppSettings } from "AppSettings";
import downloadExcel from "helper/DownloadExcel";
import LocalStorageKeys from "constants/LocalStorageConstants";
import localStorage from "../LocalStorage";

export class KyssService {
  public getKyssReportByDateRange(from_date: string, to_date: string) {
    this.getKyssReportExcelFileByDateRange({ from_date, to_date });
  }

  public getKyssReportExcelFileByDateRange(dates: {
    from_date: string;
    to_date: string;
  }) {
    const token = localStorage.getItem(LocalStorageKeys.AuthToken);

    const baseUrl = AppSettings.server.baseUrl;
    let url: string = `${baseUrl}/${ServiceType.Reports}/kyss-supplier-report-pattern`;
    let separator = "?";
    Object.keys(dates).forEach((name) => {
      url += `${separator}${encodeURI(name)}=${encodeURIComponent(
        dates[name].toString()
      )}`;
      separator = "&";
    });
    downloadExcel(url, token, "report", true);
  }
}

const kyssService = new KyssService();
export default kyssService;
