import { ProspectListState } from "../state/ProspectListState";
import {
  ProspectListAction,
  ProspectListActionTypes
} from "../actions/ProspectListActions";

export const initialState: ProspectListState = {
  noOfPages: 1,
  prospectList: undefined,
  loading: false,
  downloading: false
};

export const ProspectListReducer = (
  state = initialState,
  action: ProspectListAction
): ProspectListState => {
  switch (action.type) {
    case ProspectListActionTypes.getProspectsRequest:
      return {
        ...state,
        loading: true,
        error: undefined
      };

    case ProspectListActionTypes.getProspectsSuccess:
      return {
        ...state,
        ...action.data,
        loading: false,
        error: undefined
      };
    case ProspectListActionTypes.getProspectsError:
      return {
        ...state,
        loading: false,
        error: action.data
      };

    case ProspectListActionTypes.addProspectRequest:
      return {
        ...state,
        loading: true,
        error: undefined
      };

    case ProspectListActionTypes.addProspectSuccess:
      return {
        ...state,
        loading: false,
        error: undefined
      };
    case ProspectListActionTypes.addProspectError:
      return {
        ...state,
        loading: false,
        error: action.data
      };

    case ProspectListActionTypes.clearError:
      return {
        ...state,
        error: undefined
      };

    case ProspectListActionTypes.downloadFileRequest:
      return {
        ...state,
        downloading: true
      };

    case ProspectListActionTypes.downloadFileSuccess:
      return {
        ...state,
        downloading: false
      };
    case ProspectListActionTypes.downloadFileError:
      return {
        ...state,
        downloading: false
      };

    default:
      return state;
  }
};
