import { takeLatest, takeEvery, put } from "redux-saga/effects";
import { notification } from "antd";

import {
  DataSourceAction,
  DataSourceActions,
  DataSourceActionTypes
} from "../../store/actions/DataSourceActions";
import {
  DataSource,
  Fields,
  CreateDataSource,
  RefreshTaxFilingOptions
} from "../../store/state/DataSourceState";
import dataSourceService from "../../services/dataSourceService/DataSourceService";

import { formatFileName, downloadFile } from "../../helper/Format";
import { DsListPayload } from "../../services/dataSourceService/DataSourceService.type";
import get from "lodash/get";

export function* DataSourceListWorker(
  action: DataSourceAction
): IterableIterator<any> {
  try {
    const request = action.data as DsListPayload;
    const onSuccess =
      get(request, "page", 1) === 1
        ? DataSourceActions.dataSourceListSuccess
        : DataSourceActions.dataSourceListBatchSuccess;

    const response: any = yield dataSourceService.getDataSource(request);

    if (response.count) {
      yield put(DataSourceActions.setDataSourceCount(response.count as number));
    }
    yield put(onSuccess((response.results || response) as DataSource[]));
  } catch (error) {
    yield put(DataSourceActions.dataSourceListError(error.message || error));
  }
}

export function* DataSourceDetailsWorker(
  action: DataSourceAction
): IterableIterator<any> {
  try {
    const dataSourceID = action.data as number;
    const response: any = yield dataSourceService.getDataSourceDetails(
      dataSourceID
    );

    response.forEach((item: any) => {
      //The Keys expected in UI and from API are different.
      //converting the keys to required format.
      item.name = item.field_name;
      delete item.field_name;

      item.id = item.field_id;
      delete item.field_id;
      delete item.data_source_name;
    });

    yield put(
      DataSourceActions.getDataSourceDetailsSuccess(response as Fields[])
    );
  } catch (error) {
    yield put(
      DataSourceActions.getDataSourceDetailsError(error.message || error)
    );
  }
}

export function* FieldsListWorker(_: DataSourceAction): IterableIterator<any> {
  try {
    const response: any = yield dataSourceService.getAllFields();

    yield put(DataSourceActions.getFieldListSuccess(response as Fields[]));
  } catch (error) {
    yield put(DataSourceActions.getFieldListError(error.message || error));
  }
}

export function* CreateDataSourceWorker(
  action: DataSourceAction
): IterableIterator<any> {
  try {
    const data = action.data as CreateDataSource;
    const request = JSON.stringify(data.dataSource);

    yield dataSourceService.postCreateDataSource(request);

    yield put(DataSourceActions.createDataSourceSuccess());
    yield put(DataSourceActions.dataSourceListRequest());
    data.redirect();
    notification.success({
      message: "Data Source has been created Successfully"
    });
  } catch (error) {
    yield put(DataSourceActions.createDataSourceError(error.message || error));
    notification.error({
      message: "Unable to create Data Source",
      description: error.message
    });
  }
}

export function* DownloadTemplateWorker(
  action: DataSourceAction
): IterableIterator<any> {
  try {
    const { id, name } = action.data as DataSource;
    const response: any = yield dataSourceService.downloadTemplate(id);
    downloadFile(
      response.fields.join(",") + "\n",
      formatFileName(
        //Replacing all non alphanumeric characters to _
        `${(name as string).replace(/[\W]+/g, "_")}_Template`
      )
    );
  } catch (error) {
    notification.error({
      message: "Unable to Download",
      description: error.message
    });
  }
}

export function* refreshTaxFilingWorker(
  action: DataSourceAction
): IterableIterator<any> {
  const data = action.data as RefreshTaxFilingOptions;
  try {
    yield dataSourceService.refreshTaxFilingData({
      ds_id: data.ds_id
    });
    yield put(DataSourceActions.refreshTaxFilingSuccess());
    notification.success({
      message: "Refreshing Tax Filing Data is in Progress..."
    });
    yield put(
      DataSourceActions.pollTaxFilingRequest((data.ds_id || "") as string)
    );
  } catch (error) {
    yield put(DataSourceActions.refreshTaxFilingError(error));
    notification.error({
      message: "Unable to Refresh",
      description: error.message || error
    });
  }
}

export function* pollTaxFilingWorker(
  action: DataSourceAction
): IterableIterator<any> {
  try {
    const response: any = yield dataSourceService.pollTaxFiling(
      action.data as string
    );
    yield put(
      DataSourceActions.pollTaxFilingSuccess(response.isProcessing as boolean)
    );
  } catch (error) {
    yield put(DataSourceActions.pollTaxFilingError());
  }
}

function* DataSourceWatcher(): IterableIterator<any> {
  yield takeLatest(DataSourceActionTypes.DATA_SOURCE_REQUEST, (action) =>
    DataSourceListWorker(action)
  );

  yield takeLatest(DataSourceActionTypes.GET_FIELDS_REQUEST, (action) =>
    FieldsListWorker({
      type: action.type,
      data: (action as DataSourceAction).data
    })
  );

  yield takeEvery(DataSourceActionTypes.POST_DATA_SOURCE_REQUEST, (action) =>
    CreateDataSourceWorker({
      type: action.type,
      data: (action as DataSourceAction).data
    })
  );

  yield takeEvery(DataSourceActionTypes.DATA_SOURCE_DETAILS_REQUEST, (action) =>
    DataSourceDetailsWorker({
      type: action.type,
      data: (action as DataSourceAction).data
    })
  );

  yield takeEvery(DataSourceActionTypes.DOWNLOAD_TEMPLATE, (action) =>
    DownloadTemplateWorker({
      type: action.type,
      data: (action as DataSourceAction).data
    })
  );

  yield takeEvery(
    DataSourceActionTypes.REFRESH_TAX_FILING_STATUS_REQUEST,
    refreshTaxFilingWorker
  );

  yield takeLatest(
    DataSourceActionTypes.POLL_TAX_FILING_REQUEST,
    pollTaxFilingWorker
  );
}

export default DataSourceWatcher;
