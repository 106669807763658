import { DataSource, Fields } from "../../store/state/DataSourceState";
import ApiServiceBase from "../ApiServiceBase";
import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";
import { DsListPayload } from "./DataSourceService.type";

export class DataSourceService {
  public getDataSource(
    data?: DsListPayload
  ): DataSource[] | Promise<DataSource[]> {
    const apiService: ApiServiceBase = new ApiService(ServiceType.DataSource);

    return apiService.get({ query: { sort_by: "-created_at", ...data } });
  }
  public getAllFields(): Fields[] | Promise<Fields[]> {
    const apiService: ApiServiceBase = new ApiService(ServiceType.Fields);

    return apiService.get({});
  }

  public postCreateDataSource(data: string) {
    const apiService: ApiServiceBase = new ApiService(ServiceType.DataSource);

    return apiService.post({ route: ["create-data-source"] }, data);
  }

  public getDataSourceDetails(data: number) {
    const apiService: ApiServiceBase = new ApiService(
      ServiceType.DataSourceDetails
    );

    return apiService.get({ query: { ds_is: data } });
  }

  public downloadTemplate(data: number) {
    const apiService: ApiServiceBase = new ApiService(ServiceType.DataSource);
    return apiService.get({
      route: ["download-template"],
      query: { ds_id: data }
    });
  }

  public refreshTaxFilingData(query: {}) {
    const apiService: ApiServiceBase = new ApiService(ServiceType.DataSource);
    return apiService.post({ route: ["refresh-tax-filing"] }, query);
  }

  public pollTaxFiling(dataSourceID: string) {
    const apiService: ApiServiceBase = new ApiService(ServiceType.DataSource);

    return apiService.get({
      route: ["polling-tax-filing-status"],
      query: { ds_id: dataSourceID }
    });
  }
}

const dataSourceService = new DataSourceService();
export default dataSourceService;
