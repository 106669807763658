import ApiServiceBase from "../ApiServiceBase";
import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";

export class ProspectDetailsService {
  private readonly service: ApiServiceBase = new ApiService(
    ServiceType.Prospect
  );
  public getProspectData(prospectID: string) {
    return this.service.get({
      route: ["uploaded-data"],
      query: { prospect_id: prospectID }
    });
  }
  public editProspectData(data: string) {
    return this.service.post({ route: ["uploaded-data", "edit"] }, data);
  }
}

const prospectDetailsService = new ProspectDetailsService();
export default prospectDetailsService;
