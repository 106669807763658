import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { routes } from "../Routes";

/**
 * A reusable hook to redirect to a different route
 */
const useRedirect = () => {
  const history = useHistory();

  const redirect = useCallback(
    // Optional second param to pass some 'state' data while rerouting without showing it in the URL.
    (
      routeSelector: string | ((rotes: typeof routes) => string),
      state?: string
    ) => {
      const route =
        typeof routeSelector === "string"
          ? routeSelector
          : routeSelector(routes);
      history.push(route, state);
    },
    [history]
  );

  return redirect;
};

export default useRedirect;
