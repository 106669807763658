import kyssService from './../../services/kyssService/KyssService';
import { takeEvery, put } from "redux-saga/effects";
import {
  KyssActionTypes,
  KyssAction,
  KyssActions
} from "../../store/actions/KyssActions";

import { notification } from "antd";

function* KyssWorker(action: KyssAction): IterableIterator<any> {
  try {
    const { fromDate, toDate } = action.data as any;
    const response = yield kyssService.getKyssReportByDateRange(fromDate, toDate);
    notification.success({message: 'Successfully downloading the KYSS report'})
    yield put(KyssActions.GetKyssReportSuccess(response));
  } catch (error) {
    yield put(KyssActions.GetKyssReportError(error.message || error));
    notification.error({ message: `Unable to download the KYSS report - ${error.message || error}` });
  }
}

function* KyssWatcher(): IterableIterator<any> {
  yield takeEvery(KyssActionTypes.GET_REPORT_BY_DATE, action =>
    KyssWorker({ type: action.type, data: (action as KyssAction).data })
  );
}

export default KyssWatcher;
