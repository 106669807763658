import { QueryPath } from "../QueryPath.data";
import { Prospect } from "../../store/state/ProspectListState";
import ApiServiceBase from "../ApiServiceBase";
import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";
import { AddProspectRequest } from "./ProspectListService.type";

export class ProspectListService {
  private readonly service: ApiServiceBase = new ApiService(
    ServiceType.Prospect
  );
  public getProspects(query: QueryPath): Prospect[] | Promise<Prospect[]> {
    return this.service.get({ route: ["list"], query: query.query });
  }

  public addProspect(data: AddProspectRequest) {
    return this.service.post({ route: ["create-single-prospect"] }, data);
  }
}

const prospectListService = new ProspectListService();
export default prospectListService;
