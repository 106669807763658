import { takeEvery, takeLatest, put } from "redux-saga/effects";

import get from "lodash/get";

// Actions
import {
  DetailedViewActions,
  DetailedViewActionTypes,
  DetailedViewAction,
  EditUploadedData
} from "../../store/actions/DetailedViewActions";

// Services
import prospectDetailsService from "../../services/prospectDetailsService/ProspectDetailsService";
import taxFilingService from "../../services/taxFilingService/TaxFilingService";
import taxPayerService from "../../services/taxPayerService/TaxPayerService";

import { KeyValue } from "../../Constants";

//state
import {
  UploadedData,
  TaxFilingData,
  TaxPayerData,
  TaxFilingFilterOptions
} from "../../store/state/DetailedViewState";
import { notification } from "antd";
import { safeParseJson } from "../../helper/Format";

export function* uploadedProspectDataWorker(
  action: DetailedViewAction
): IterableIterator<any> {
  const data = action.data as string;
  try {
    const response: any = yield prospectDetailsService.getProspectData(data);
    yield put(
      DetailedViewActions.getUploadedProspectDataSuccess(
        response as UploadedData[]
      )
    );
  } catch (error) {
    yield put(DetailedViewActions.getUploadedProspectDataError(error));

    notification.error({
      message: "An Error occurred",
      description: error.message || error
    });
  }
}

export function* editUploadedProspectDataWorker(
  action: DetailedViewAction
): IterableIterator<any> {
  const { setErrors, uploadedData } = action.data as EditUploadedData;
  try {
    const response: any = yield prospectDetailsService.editProspectData(
      JSON.stringify(uploadedData)
    );
    yield put(
      DetailedViewActions.getUploadedProspectDataSuccess(
        response as UploadedData[]
      )
    );
    yield put(
      DetailedViewActions.getUploadedProspectDataRequest(
        get(uploadedData, "prospectID", "")
      )
    );

    notification.success({
      message: "Edited Successfully"
    });
  } catch (error) {
    setErrors(safeParseJson(error.message || {}));
    yield put(
      DetailedViewActions.getUploadedProspectDataError(error.message || error)
    );
  }
}

export function* taxFilingWorker(
  action: DetailedViewAction
): IterableIterator<any> {
  const data = action.data as KeyValue;
  try {
    const response: any = yield taxFilingService.getTaxFilingData(data);

    const formattedResponse: TaxFilingData = formatTaxFilingData(response);

    yield put(
      DetailedViewActions.getTaxFilingSuccess(
        formattedResponse as TaxFilingData
      )
    );
  } catch (error) {
    yield put(DetailedViewActions.getTaxFilingError(error));
    notification.error({
      message: "An Error occurred",
      description: error.message || error
    });
  }
}

export function* taxPayerWorker(
  action: DetailedViewAction
): IterableIterator<any> {
  const data = action.data as string;
  try {
    const response: any = yield taxPayerService.getTaxPayersData(data);
    let formattedResponse: TaxPayerData;
    const items: KeyValue = {};
    let mainAddress: KeyValue = {};
    let natureOfBusiness: string;
    let natureOfBusinessActivities: string;
    const secondaryAddressList: KeyValue[] = [];

    //To get the secondary address of the tax payer
    get(response, "[0].raw_data.Secondary Address", []).forEach((item: any) => {
      secondaryAddressList.push({
        ...item.Address,
        "Nature of Business": item["Nature of Business"]
      });
    });

    // To get the primary address
    mainAddress = get(response, "[0].raw_data.Primary Address.Address");

    //To get nature of business from primary address
    natureOfBusiness = get(
      response,
      "[0].raw_data.Primary Address.Nature of Business"
    );

    //To get nature of business activities
    natureOfBusinessActivities = get(
      response,
      "[0].raw_data.Nature of Business Activities"
    );

    // To get details of the tax payer

    const responseData = Object.keys(get(response, "[0].raw_data", {}));
    if (responseData && responseData.length) {
      responseData.forEach((key: string) => {
        const value = response[0].raw_data[key];
        //use only values of string, ignore others
        if (typeof value === "string" && value !== "") {
          items[key] = value;
        }
      });
    }

    formattedResponse = {
      taxPayer: {
        ...items,
        ...mainAddress,
        "Nature of Business": natureOfBusiness,
        "Nature of Business Activities": natureOfBusinessActivities
      },
      taxPayerSecondaryAddressList: secondaryAddressList,
      tax_payer_updated_at: get(response, "[0].tax_payer_updated_at")
    };

    yield put(
      DetailedViewActions.getTaxPayerSuccess(formattedResponse as TaxPayerData)
    );
  } catch (error) {
    yield put(DetailedViewActions.getTaxPayerError(error));
    notification.error({
      message: "An Error occurred",
      description: error.message || error
    });
  }
}

export function* refreshTaxFilingWorker(
  action: DetailedViewAction
): IterableIterator<any> {
  const data = action.data as TaxFilingFilterOptions;
  try {
    yield taxFilingService.refreshTaxFilingData(data);
    yield put(DetailedViewActions.refreshTaxFilingSuccess());

    yield put(DetailedViewActions.getTaxFilingRequest(data));
  } catch (error) {
    yield put(DetailedViewActions.refreshTaxFilingError(error));
    notification.error({
      message: "Unable to Refresh",
      description: error.message || error
    });
  }
}

const formatTaxFilingData = (response: any): TaxFilingData => {
  const formattedResponse: TaxFilingData = {
    last_updated_on: get(response, "[0].last_updated_at"),
    taxFiling: []
  };
  if (response && response.length) {
    response.forEach((item: any) => delete item.last_updated_at);
  }

  return { ...formattedResponse, taxFiling: response };
};

function* DetailedViewWatcher(): IterableIterator<any> {
  yield takeEvery(DetailedViewActionTypes.UploadProspectDataRequest, action =>
    uploadedProspectDataWorker({
      type: action.type,
      data: (action as DetailedViewAction).data
    })
  );
  yield takeEvery(DetailedViewActionTypes.TaxFilingRequest, action =>
    taxFilingWorker({
      type: action.type,
      data: (action as DetailedViewAction).data
    })
  );
  yield takeEvery(DetailedViewActionTypes.TaxPayerRequest, action =>
    taxPayerWorker({
      type: action.type,
      data: (action as DetailedViewAction).data
    })
  );
  yield takeEvery(
    DetailedViewActionTypes.EditUploadProspectDataRequest,
    action =>
      editUploadedProspectDataWorker({
        type: action.type,
        data: (action as DetailedViewAction).data
      })
  );
  yield takeLatest(DetailedViewActionTypes.RefreshTaxFilingRequest, action =>
    refreshTaxFilingWorker({
      type: action.type,
      data: (action as DetailedViewAction).data
    })
  );
}

export default DetailedViewWatcher;
