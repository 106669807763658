import { QueryPath } from "../QueryPath.data";
import { FileDetails } from "../../store/state/FileListState";
import ApiServiceBase from "../ApiServiceBase";
import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";

export class FileListService {
  public getFiles(query: QueryPath): FileDetails[] | Promise<FileDetails[]> {
    const apiService: ApiServiceBase = new ApiService(ServiceType.FileList);

    return apiService.get(query);
  }
  public getFileDetails(fileID: string) {
    const apiService: ApiServiceBase = new ApiService(ServiceType.FileList);

    return apiService.get({ route: [fileID] });
  }
  public pollFileList(interval: number) {
    const apiService: ApiServiceBase = new ApiService(ServiceType.FileUpload);

    return apiService.get({
      route: ["processing-status"],
      query: { interval }
    });
  }
}

const fileListService = new FileListService();
export default fileListService;
