import actionCreatorFactory from "typescript-fsa";
import { NotificationMessage } from "../../services/notification/NotificationService.type";

const actionCreator = actionCreatorFactory();

const getAllNotificationsActions = actionCreator.async<
  void,
  NotificationMessage[],
  Error
>("GET_ALL_NOTIFICATIONS");

const getNewNotificationsActions = actionCreator.async<
  void,
  NotificationMessage[],
  Error
>("GET_NEW_NOTIFICATIONS");

const closeNotificationActions = actionCreator.async<number, void, Error>(
  "CLOSE_NOTIFICATION"
);
export const notificationActions = {
  getAllNotifications: getAllNotificationsActions,
  getNewNotifications: getNewNotificationsActions,
  closeNotification: closeNotificationActions
};
