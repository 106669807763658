import { StoreAction } from "../../store/StoreHelper";
import {
  UniqueFile,
  FileProgress,
  UploadError
} from "../../store/state/FileUploadState";

export enum FileUploadActionTypes {
  SET_ERROR = "SET_ERROR",
  FILE_UPLOAD_REQUEST = "FILE_UPLOAD_REQUEST",
  FILE_UPLOAD_PROGRESS = "FILE_UPLOAD_PROGRESS",
  FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS",
  FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR"
}

export type FileUploadPayload =
  | UniqueFile
  | FileProgress
  | UploadError
  | string
  | undefined;

export type FileUploadAction = StoreAction<
  FileUploadActionTypes,
  FileUploadPayload
>;

export class FileUploadActions {
  public static postFileUploadRequest(data: UniqueFile): FileUploadAction {
    return {
      type: FileUploadActionTypes.FILE_UPLOAD_REQUEST,
      data
    };
  }
  public static setError(data: UploadError): FileUploadAction {
    return {
      type: FileUploadActionTypes.SET_ERROR,
      data
    };
  }
  public static postFileUploadSuccess(data: string): FileUploadAction {
    return {
      type: FileUploadActionTypes.FILE_UPLOAD_SUCCESS,
      data
    };
  }
  public static postFileUploadError(data: UploadError): FileUploadAction {
    return {
      type: FileUploadActionTypes.FILE_UPLOAD_ERROR,
      data
    };
  }
  public static updateFileProgress(data: FileProgress) {
    return {
      type: FileUploadActionTypes.FILE_UPLOAD_PROGRESS,
      data
    };
  }
}
