import ApiServiceBase from "../ApiServiceBase";
import ApiService from "../ApiService";
import { ServiceType } from "../ServiceType.data";
import { POLLING_INTERVAL, POLLING_DELTA } from "../../Constants";
import { NotificationListPayload } from "./NotificationService.type";

const service: ApiServiceBase = new ApiService(ServiceType.Notification);
class NotificationService {
  public getAllNotifications():
    | Promise<NotificationListPayload>
    | NotificationListPayload {
    return service.get({ route: ["list"] });
  }
  public getNewNotifications():
    | Promise<NotificationListPayload>
    | NotificationListPayload {
    return service.get({
      route: ["list"],
      query: { timeInterval: POLLING_INTERVAL + POLLING_DELTA }
    });
  }

  public closeNotification(notificationId: number) {
    return service.patch(
      {
        route: ["close", notificationId.toString()]
      },
      {}
    );
  }
}

const notificationService = new NotificationService();
export default notificationService;
