import ApiServiceBase from "../ApiServiceBase";
import ApiService from "../ApiService";
import { ServiceType } from "../ServiceType.data";
import { ResetPasswordPayload } from "./ResetService.data";

export class ResetPasswordService {
  private readonly service: ApiServiceBase = new ApiService(ServiceType.Auth);
  public forgetPassword(email: string) {
    return this.service.post({ route: ["reset-password"] }, { email });
  }

  public resetPassword(data: ResetPasswordPayload) {
    return this.service.post({ route: ["reset-password-confirm"] }, data);
  }
}

const resetPasswordService = new ResetPasswordService();
export default resetPasswordService;
