import eligibilityService from "./EligibilityService";

export const EligibilityService = {
  eligibilityDetails: eligibilityService.eligibilityDetails,
  triggerManualFileVerification:
    eligibilityService.triggerManualFileVerification,
  manualLoanCalculation: eligibilityService.manualLoanCalculation,
  getSubIndustries: eligibilityService.getSubIndustries,
  getEligibilityList: eligibilityService.getEligibilityList,
  getCapexEligibilityList: eligibilityService.getCapexEligibilityList,
  getCapexEligibilityDetails: eligibilityService.getCapexEligibilityDetails,
  presignedDocsRequest: eligibilityService.presignedDocsRequest,
  getSitePhotos: eligibilityService.getSitePhotos,
  uploadByAdmin: eligibilityService.uploadByAdmin,
  addBankDetails: eligibilityService.addBankDetails,
  approveRejectByAdmin: eligibilityService.approveRejectByAdmin,
  approvePhotosByAdmin: eligibilityService.approvePhotosByAdmin,
  rejectPhotosByAdmin: eligibilityService.rejectPhotosByAdmin,
  cancelRecord: eligibilityService.cancelRecord,
  estimatePreviousStage: eligibilityService.estimatePreviousStage,
  invoicePreviousStage: eligibilityService.invoicePreviousStage,
  updateEligibilityStatus: eligibilityService.updateEligibilityStatus,
  updateAccountCreationStatus: eligibilityService.updateAccountCreationStatus,
  acceptRejectReceivables: eligibilityService.acceptRejectReceivables,
  updateReceivableAmount: eligibilityService.updateReceivableAmount,

  uploadGstrConfig: eligibilityService.uploadGstrConfig,
  deleteGstrConfig: eligibilityService.deleteGstrConfig
};
