import {
  DataSource,
  Fields,
  CreateDataSource,
  CreateDataSourceError,
  TaxFilingData,
  RefreshTaxFilingOptions
} from "../../store/state/DataSourceState";

import { DsListPayload } from "../../services/dataSourceService/DataSourceService.type";

export enum DataSourceActionTypes {
  DATA_SOURCE_REQUEST = "DATA_SOURCE_REQUEST",
  DATA_SOURCE_SUCCESS = "DATA_SOURCE_SUCCESS",
  DATA_SOURCE_BATCH_SUCCESS = "DATA_SOURCE_BATCH_SUCCESS",
  DATA_SOURCE_ERROR = "DATA_SOURCE_ERROR",
  GET_FIELDS_REQUEST = "GET_FIELDS_REQUEST",
  GET_FIELDS_SUCCESS = "GET_FIELDS_SUCCESS",
  GET_FIELDS_ERROR = "GET_FIELDS_ERROR",
  POST_DATA_SOURCE_REQUEST = "POST_DATA_SOURCE_REQUEST",
  POST_DATA_SOURCE_SUCCESS = "POST_DATA_SOURCE_SUCCESS",
  POST_DATA_SOURCE_ERROR = "POST_DATA_SOURCE_ERROR",
  DATA_SOURCE_DETAILS_REQUEST = "DATA_SOURCE_DETAILS_REQUEST",
  DATA_SOURCE_DETAILS_SUCCESS = "DATA_SOURCE_DETAILS_SUCCESS",
  DATA_SOURCE_DETAILS_ERROR = "DATA_SOURCE_DETAILS_ERROR",
  DOWNLOAD_TEMPLATE = "DOWNLOAD_TEMPLATE",
  SET_DATA_SOURCE_COUNT = "SET_DATA_SOURCE_COUNT",
  REFRESH_TAX_FILING_STATUS_REQUEST = "REFRESH_TAX_FILING_STATUS_REQUEST",
  REFRESH_TAX_FILING_STATUS_SUCCESS = "REFRESH_TAX_FILING_STATUS_SUCCESS",
  REFRESH_TAX_FILING_STATUS_ERROR = "REFRESH_TAX_FILING_STATUS_ERROR",
  POLL_TAX_FILING_REQUEST = "POLL_TAX_FILING_REQUEST",
  POLL_TAX_FILING_SUCCESS = "POLL_TAX_FILING_SUCCESS",
  POLL_TAX_FILING_ERROR = "POLL_TAX_FILING_ERROR"
}

export interface DataSourceAction {
  type: DataSourceActionTypes;
  data?:
    | string
    | number
    | DataSource[]
    | Fields[]
    | DsListPayload
    | CreateDataSource
    | Error
    | CreateDataSourceError
    | RefreshTaxFilingOptions
    | TaxFilingData
    | boolean;
}
export class DataSourceActions {
  public static dataSourceListRequest(data?: DsListPayload): DataSourceAction {
    return {
      type: DataSourceActionTypes.DATA_SOURCE_REQUEST,
      data
    };
  }
  public static dataSourceListSuccess(data: DataSource[]): DataSourceAction {
    return {
      type: DataSourceActionTypes.DATA_SOURCE_SUCCESS,
      data
    };
  }
  public static dataSourceListBatchSuccess(
    data: DataSource[]
  ): DataSourceAction {
    return {
      type: DataSourceActionTypes.DATA_SOURCE_BATCH_SUCCESS,
      data
    };
  }
  public static dataSourceListError(data: Error): DataSourceAction {
    return {
      type: DataSourceActionTypes.DATA_SOURCE_ERROR,
      data
    };
  }

  public static getFieldListRequest(data?: string): DataSourceAction {
    return {
      type: DataSourceActionTypes.GET_FIELDS_REQUEST,
      data
    };
  }
  public static getFieldListSuccess(data: Fields[]): DataSourceAction {
    return {
      type: DataSourceActionTypes.GET_FIELDS_SUCCESS,
      data
    };
  }
  public static getFieldListError(data: Error): DataSourceAction {
    return {
      type: DataSourceActionTypes.DATA_SOURCE_ERROR,
      data
    };
  }

  public static createDataSourceRequest(
    data: CreateDataSource
  ): DataSourceAction {
    return {
      type: DataSourceActionTypes.POST_DATA_SOURCE_REQUEST,
      data
    };
  }
  public static createDataSourceSuccess(): DataSourceAction {
    return {
      type: DataSourceActionTypes.POST_DATA_SOURCE_SUCCESS
    };
  }
  public static createDataSourceError(
    data: CreateDataSourceError
  ): DataSourceAction {
    return {
      type: DataSourceActionTypes.POST_DATA_SOURCE_ERROR,
      data
    };
  }

  public static getDataSourceDetailsRequest(data?: number): DataSourceAction {
    return {
      type: DataSourceActionTypes.DATA_SOURCE_DETAILS_REQUEST,
      data
    };
  }
  public static getDataSourceDetailsSuccess(data: Fields[]): DataSourceAction {
    return {
      type: DataSourceActionTypes.DATA_SOURCE_DETAILS_SUCCESS,
      data
    };
  }
  public static getDataSourceDetailsError(data: Error): DataSourceAction {
    return {
      type: DataSourceActionTypes.DATA_SOURCE_DETAILS_ERROR,
      data
    };
  }
  public static downloadDataSourceTemplate(data: DataSource): DataSourceAction {
    return {
      type: DataSourceActionTypes.DOWNLOAD_TEMPLATE,
      data
    };
  }
  public static setDataSourceCount(count: number): DataSourceAction {
    return {
      type: DataSourceActionTypes.SET_DATA_SOURCE_COUNT,
      data: count
    };
  }

  public static refreshTaxFilingRequest(
    data: RefreshTaxFilingOptions
  ): DataSourceAction {
    return {
      type: DataSourceActionTypes.REFRESH_TAX_FILING_STATUS_REQUEST,
      data
    };
  }

  public static refreshTaxFilingSuccess(): DataSourceAction {
    return {
      type: DataSourceActionTypes.REFRESH_TAX_FILING_STATUS_SUCCESS,
      data: undefined
    };
  }

  public static refreshTaxFilingError(data: Error): DataSourceAction {
    return {
      type: DataSourceActionTypes.REFRESH_TAX_FILING_STATUS_ERROR,
      data
    };
  }

  public static pollTaxFilingRequest(dataSourceID: string): DataSourceAction {
    return {
      type: DataSourceActionTypes.POLL_TAX_FILING_REQUEST,
      data: dataSourceID
    };
  }

  public static pollTaxFilingSuccess(data: boolean): DataSourceAction {
    return {
      type: DataSourceActionTypes.POLL_TAX_FILING_SUCCESS,
      data
    };
  }

  public static pollTaxFilingError(): DataSourceAction {
    return {
      type: DataSourceActionTypes.POLL_TAX_FILING_ERROR
    };
  }
}
