import { StoreAction } from "../../store/StoreHelper";
import {
  UploadedData,
  TaxFilingData,
  TaxPayerData,
  TaxFilingFilterOptions
} from "../../store/state/DetailedViewState";
import { KeyValue } from "../../Constants";

export enum DetailedViewActionTypes {
  UploadProspectDataRequest = "UPLOADED_PROSPECT_DATA_REQUEST",
  UploadProspectDataSuccess = "UPLOADED_PROSPECT_DATA_SUCCESS",
  UploadProspectDataError = "UPLOADED_PROSPECT_DATA_ERROR",
  EditUploadProspectDataRequest = "EDIT_UPLOADED_PROSPECT_DATA_REQUEST",
  EditUploadProspectDataSuccess = "EDIT_UPLOADED_PROSPECT_DATA_SUCCESS",
  EditUploadProspectDataError = "EDIT_UPLOADED_PROSPECT_DATA_ERROR",
  TaxFilingRequest = "TAX_FILING_REQUEST",
  TaxFilingSuccess = "TAX_FILING_SUCCESS",
  TaxFilingError = "TAX_FILING_ERROR",
  TaxPayerRequest = "TAX_PAYER_REQUEST",
  TaxPayerSuccess = "TAX_PAYER_SUCCESS",
  TaxPayerError = "TAX_PAYER_ERROR",
  RefreshTaxFilingRequest = "REFRESH_TAX_FILING_REQUEST",
  RefreshTaxFilingSuccess = "REFRESH_TAX_FILING_SUCCESS",
  RefreshTaxFilingError = "REFRESH_TAX_FILING_ERROR"
}

export type DetailedViewPayload =
  | string
  | UploadedData[]
  | TaxFilingData
  | TaxPayerData
  | KeyValue
  | EditUploadedData
  | TaxFilingFilterOptions
  | Error
  | undefined;

export type DetailedViewAction = StoreAction<
  DetailedViewActionTypes,
  DetailedViewPayload
>;

export interface EditUploadedData {
  uploadedData: KeyValue;
  setErrors: (errors: KeyValue) => void;
}

export class DetailedViewActions {
  public static getUploadedProspectDataRequest(
    data: string
  ): DetailedViewAction {
    return {
      type: DetailedViewActionTypes.UploadProspectDataRequest,
      data
    };
  }
  public static getUploadedProspectDataSuccess(
    data: UploadedData[]
  ): DetailedViewAction {
    return {
      type: DetailedViewActionTypes.UploadProspectDataSuccess,
      data
    };
  }
  public static getUploadedProspectDataError(data: Error): DetailedViewAction {
    return {
      type: DetailedViewActionTypes.UploadProspectDataError,
      data
    };
  }

  public static editUploadedProspectDataRequest(
    data: EditUploadedData
  ): DetailedViewAction {
    return {
      type: DetailedViewActionTypes.EditUploadProspectDataRequest,
      data
    };
  }
  public static editUploadedProspectDataSuccess(): DetailedViewAction {
    return {
      type: DetailedViewActionTypes.EditUploadProspectDataSuccess
    };
  }
  public static editUploadedProspectDataError(data: Error): DetailedViewAction {
    return {
      type: DetailedViewActionTypes.EditUploadProspectDataError,
      data
    };
  }

  public static getTaxFilingRequest(
    data: TaxFilingFilterOptions
  ): DetailedViewAction {
    return {
      type: DetailedViewActionTypes.TaxFilingRequest,
      data
    };
  }
  public static getTaxFilingSuccess(data: TaxFilingData): DetailedViewAction {
    return {
      type: DetailedViewActionTypes.TaxFilingSuccess,
      data
    };
  }
  public static getTaxFilingError(data: Error): DetailedViewAction {
    return {
      type: DetailedViewActionTypes.TaxFilingError,
      data
    };
  }

  public static refreshTaxFilingRequest(
    data: TaxFilingFilterOptions
  ): DetailedViewAction {
    return {
      type: DetailedViewActionTypes.RefreshTaxFilingRequest,
      data
    };
  }
  public static refreshTaxFilingSuccess(): DetailedViewAction {
    return {
      type: DetailedViewActionTypes.RefreshTaxFilingSuccess,
      data: undefined
    };
  }
  public static refreshTaxFilingError(data: Error): DetailedViewAction {
    return {
      type: DetailedViewActionTypes.RefreshTaxFilingError,
      data
    };
  }

  public static getTaxPayerRequest(data: string): DetailedViewAction {
    return {
      type: DetailedViewActionTypes.TaxPayerRequest,
      data
    };
  }
  public static getTaxPayerSuccess(data: TaxPayerData): DetailedViewAction {
    return {
      type: DetailedViewActionTypes.TaxPayerSuccess,
      data
    };
  }
  public static getTaxPayerError(data: Error): DetailedViewAction {
    return {
      type: DetailedViewActionTypes.TaxPayerError,
      data
    };
  }
}
