import {
  KyssAction,
  KyssActionTypes
} from "../../store/actions/KyssActions";

export interface KyssState {
    loading: boolean;
    reportBlob: Blob | null;
    fromDate: string | null;
    toDate: string | null;
    error: string | null;
}

export const initialState: KyssState = {
  loading: false,
  reportBlob: null,
  fromDate: '',
  toDate: '',
  error: ''
};

export const KyssReducer = (
  state = initialState,
  action: KyssAction
): KyssState => {
  switch (action.type) {
    case KyssActionTypes.GET_REPORT_BY_DATE:
      return {
        ...state,
        loading: true,
        fromDate: action.data?.fromDate,
        toDate: action.data?.toDate
      };

    case KyssActionTypes.GET_REPORT_BY_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        reportBlob: action.data
      };
    case KyssActionTypes.GET_REPORT_BY_DATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data
      };

    default:
      return state;
  }
};
