import { takeEvery, takeLatest, put } from "redux-saga/effects";

// Actions
import {
  FileUploadAction,
  FileUploadActions,
  FileUploadActionTypes
} from "../../store/actions/FileUploadActions";

import {
  FetchFileActionTypes,
  FetchFileAction,
  FetchFileActions
} from "../../store/actions/FileListActions";
import { FailedRecordsActions } from "../../store/actions/FailedRecordsActions";

// Services
import fileUploadService from "../../services/fileUploadService/FileUploadService";
import fileListService from "../../services/fileListService/FileListService";

import { QueryPath } from "../../services/QueryPath.data";
import { FileDetails } from "../../store/state/FileListState";

// Lodash
import get from "lodash/get";
import { UniqueFile, Progress } from "../../store/state/FileUploadState";
import { store } from "../../store/store";

import { notification } from "antd";

import { localNumber } from "../../helper/Format";
import { validateProspectsFile } from "../../helper/ValidateFile";
import { PageCount, FileListPollInterval } from "../../Constants";
import { Logging } from "../../helper/Logging";

export function* fileUploadWorker(
  action: FileUploadAction
): IterableIterator<any> {
  const data = action.data as UniqueFile;
  try {
    const errorMessage = validateProspectsFile(data);
    if (errorMessage) {
      throw Error(errorMessage);
    }

    yield fileUploadService.uniqueFileCheck(data.file.name);

    if (data.upload) {
      const response = yield fileUploadService.postFile(
        data,
        (progress: Progress) => {
          store.dispatch(
            FileUploadActions.updateFileProgress({ id: data.id, progress })
          );
        }
      );
      yield put(FileUploadActions.postFileUploadSuccess(data.id));

      notification.success({
        message: "Upload Successfully",
        description: get(response, "message", "")
      });
    }
  } catch (error) {
    yield put(
      FileUploadActions.postFileUploadError({
        id: data.id,
        message: error.message
      })
    );
  }
}

export function* fileListWorker(
  action: FetchFileAction
): IterableIterator<any> {
  try {
    const request = action.data as QueryPath;
    const onSuccess =
      get(request, "query.page", 1) === 1
        ? FetchFileActions.fetchFileSuccess
        : FetchFileActions.fetchFileBatchSuccess;

    const response: any = yield fileListService.getFiles(request);

    if (response.count) {
      yield put(FetchFileActions.setFileListCount(response.count as number));
    }

    const fileList = formatFileList(response.results || response);
    yield put(onSuccess(fileList as FileDetails[]));
  } catch (error) {
    yield put(FetchFileActions.fetchFileError(error));
  }
}

const formatFileList = (response: any) => {
  return response.map((item: any) => ({
    ...item,
    passed_records:
      typeof item.passed_records === "number" &&
      localNumber(item.passed_records),
    failed_records:
      typeof item.failed_records === "number" &&
      localNumber(item.failed_records),
    total_records:
      typeof item.total_records === "number" && localNumber(item.total_records)
  }));
};

export function* refreshWorker(action: FetchFileAction): IterableIterator<any> {
  try {
    const response: any = yield fileListService.getFiles(
      action.data as QueryPath
    );
    const fileList = formatFileList(response) as FileDetails[];

    if (fileList) {
      yield put(
        FailedRecordsActions.getProspectsRequest({
          query: {
            file_id: fileList[0].id,
            is_failed: true,
            paginate: true,
            page_size: PageCount,
            page: 1
          }
        })
      );
    }
    yield put(FetchFileActions.refreshFileSuccess(fileList));
  } catch (error) {
    yield put(FetchFileActions.refreshFileError(error.message || error));
  }
}

export function* pollFileListWorker(_: FetchFileAction): IterableIterator<any> {
  try {
    const response: any = yield fileListService.pollFileList(
      FileListPollInterval
    );
    yield put(
      FetchFileActions.pollFileListSuccess(
        formatFileList(response) as FileDetails[]
      )
    );
  } catch (error) {
    Logging.debug(error);
  }
}

export function* fileDetailsWorker(
  action: FetchFileAction
): IterableIterator<any> {
  try {
    const fileID = action.data as string;

    const response: any = yield fileListService.getFileDetails(fileID);

    yield put(FetchFileActions.saveFileDetailsSuccess(response as FileDetails));
  } catch (error) {
    FetchFileActions.saveFileDetailsError(error);
  }
}

function* FileWatcher(): IterableIterator<any> {
  yield takeEvery(FileUploadActionTypes.FILE_UPLOAD_REQUEST, fileUploadWorker);
  yield takeLatest(FetchFileActionTypes.FETCH_FILE_REQUEST, fileListWorker);
  yield takeLatest(FetchFileActionTypes.REFRESH_FILE_REQUEST, refreshWorker);
  yield takeLatest(
    FetchFileActionTypes.SAVE_FILE_DETAILS_REQUEST,
    fileDetailsWorker
  );
  yield takeLatest(
    FetchFileActionTypes.POLL_FILE_LIST_REQUEST,
    pollFileListWorker
  );
}

export default FileWatcher;
