import { useMemo } from "react";
import { Dispatch, AnyAction } from "redux";
import { useSelector, useDispatch } from "react-redux";

import { LoginState } from "../../state/LoginState";
import { LoginRequest, LoginActions } from "../../actions/LoginActions";
import { ApplicationState } from "../../RootReducers";

interface Selectors {
  loginState: LoginState;
}
interface ActionDispatchers {
  login: (loginInfo: LoginRequest) => void;
}

/**
 *  accesses Redux, returns required state,actions
 */
const useLogin = (): Selectors & ActionDispatchers => {
  const dispatch = useDispatch<Dispatch<AnyAction>>();
  const selectors = useSelector(stateSelector);
  const actionDispatchers = useMemo(() => actions(dispatch), [dispatch]);

  return { ...selectors, ...actionDispatchers };
};

const stateSelector = (state: ApplicationState): Selectors => ({
  loginState: state.login
});

const actions = (dispatch: Dispatch<AnyAction>): ActionDispatchers => ({
  login: (loginInfo: LoginRequest) => {
    dispatch(LoginActions.LoginRequest(loginInfo));
  }
});

export default useLogin;
