import React, { Suspense } from "react";
import "./App.css";

//components
import SidebarComponent from "./components/sidebar";
import useLogin from "./store/hooks/auth/useLogin";

const RoutesContainer = React.lazy(() => import("./RoutesContainer"));

const App: React.FC = () => {
  const {
    loginState: { accessDetails }
  } = useLogin();
  return (
    <div className="App">
      {/* Show Sidebar only if user is logged in */}
      {accessDetails && accessDetails.token && <SidebarComponent />}
      <Suspense fallback={null}>
        <RoutesContainer />
      </Suspense>
    </div>
  );
};

export default App;
