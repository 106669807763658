import ApiServiceBase from "../ApiServiceBase";
import { ServiceType } from "../ServiceType.data";
import ApiService from "../ApiService";

export class TaxPayerService {
  private readonly service: ApiServiceBase = new ApiService(
    ServiceType.TaxPayer
  );
  public getTaxPayersData(prospectID: string) {
    return this.service.get({
      query: { prospect_id: prospectID, gstin_type: 1 }
    }); //gstin_type: 1 to specify TaxPayer
  }
}

const taxPayerService = new TaxPayerService();
export default taxPayerService;
