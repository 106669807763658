import { all } from "redux-saga/effects";
import FileWatcher from "./store/sagas/FileUploadSaga";
import FailedRecordsWatcher from "./store/sagas/FailedRecordsSaga";
import ProspectListWatcher from "./store/sagas/ProspectListSaga";
import DetailedViewWatcher from "./store/sagas/DetailedViewSaga";
import DataSourceWatcher from "./store/sagas/DataSourceSaga";
import LoginWatcher from "./store/sagas/LoginSaga";
import ResetPasswordWatcher from "./store/sagas/ResetPasswordSaga";
import EligibilityWatcher from "./store/eligibility/sagas";
import NotificationWatcher from "./store/notification/sagas";
import KyssWatcher from "store/sagas/ReportsSaga";

export default function* sagas() {
  yield all([
    // add sagas/watchers
    LoginWatcher(),
    ResetPasswordWatcher(),
    FileWatcher(),
    FailedRecordsWatcher(),
    ProspectListWatcher(),
    DetailedViewWatcher(),
    DataSourceWatcher(),
    EligibilityWatcher(),
    NotificationWatcher(),
    KyssWatcher()
  ]);
}
